
// //* btns ---------------------------------------------

// @include smDesktop {
//   .bl-upload-upsert-btns {
//     @include flex-row;
//     min-width: 1200px;
//     max-width: 1200px;
//     margin: 20px auto;
//     justify-content: flex-end;
//     > button {
//       background-color: #0b6277;
//       color: #fff;
//       outline: none;
//       justify-content: flex-end;
//       margin-left: 10px;
//     }
//   }
// }
// @include lgDesktop {
//   .bl-upload-upsert-btns {
//     @include flex-row;
//     min-width: 1600px;
//     max-width: 1600px;
//     margin: 20px auto;
//     justify-content: flex-end;
//     > button {
//       background-color: #0b6277;
//       color: #fff;
//       outline: none;
//       justify-items: flex-end;
//       margin-left: 10px;
//     }
//   }
// }

// //* conditions ---------------------------------------------

// @include smDesktop {
//   .bl-upload-upsert-condition {
//     border: 1px solid #c5dade;
//     max-width: 1200px;
//     min-width: 1200px;
//     margin: 0 auto;
//     font-size: 12px;
//     font-weight: 400;
//     .first-row {
//       @include flex-row;
//       justify-content: space-between;
//       align-items: center;
//       flex-wrap: nowrap;
//       .search-th {
//         background: #f5f9fa;
//         color: #8eb3ba;
//         padding: 20px 0;
//         width: 15%;
//         text-align: center;
//         border-right: 1px solid #c5dade;
//         border-left: 1px solid #c5dade;
//         &:nth-child(1) {
//           border-left: none;
//         }
//       }
//       .search-td {
//         background: #fff;
//         text-align: center;
//         margin: 0 auto;
//       }
//     }
//   }
// }

// @include lgDesktop {
//   .bl-upload-upsert-condition {
//     border: 1px solid #c5dade;
//     max-width: 1600px;
//     min-width: 1600px;
//     margin: 0 auto;
//     font-size: 12px;
//     font-weight: 400;
//     .first-row {
//       @include flex-row;
//       justify-content: space-between;
//       align-items: center;
//       flex-wrap: nowrap;
//       border-bottom: 1px solid #c5dade;
//       .search-th {
//         background: #f5f9fa;
//         color: #8eb3ba;
//         padding: 20px 0;
//         width: 15%;
//         text-align: center;
//         border-right: 1px solid #c5dade;
//         border-left: 1px solid #c5dade;
//         &:nth-child(1) {
//           border-left: none;
//         }
//       }
//       .search-td {
//         background: #fff;
//         text-align: center;
//         margin: 0 auto;
//       }
//     }
//     .second-row {
//       @include flex-row;
//       justify-self: flex-start;
//       align-items: center;
//       flex-wrap: nowrap;
//       .search-th {
//         background: #f5f9fa;
//         color: #8eb3ba;
//         padding: 20px 0;
//         width: 15%;
//         text-align: center;
//         border-right: 1px solid #c5dade;
//         border-left: 1px solid #c5dade;
//         &:nth-child(1) {
//           border-left: none;
//         }
//       }
//       .search-td {
//         background: #fff;
//         text-align: center;
//         margin: 0 auto;
//         width: 180px;
//       }
//       .empty {
//         padding: 20px 0;
//         text-align: center;
//         border-left: 1px solid #c5dade;
//         width: 1059px;
//       }
//     }
//   }
// }

// //* result ---------------------------------------------

// @include smDesktop {
//   .bl-upload-upsert-result {
//     margin: 30px auto;
//     max-width: 1200px;
//     min-width: 1200px;
//     @include flex-row;
//     // todo. 임시 => bl 업로드하는 영역을 최대로 할 필요성 있음
//     height: 700px;

//     #service-area {
//       @include flex-row;
//       height: 100%;
//       width: 100%;
//       #left-side {
//         @include flex-column;
//         display: flex;
//         flex: 1 1;
//       }
//       .main-section,
//       .sub-section {
//         @include flex-column;
//       }
//       .main-section {
//         display: flex;
//         overflow-x: hidden;
//         height: 100%;
//         background-color: #fff;
//       }
//       .sub-section {
//         flex: 1 1;
//         margin-left: 30px;
//         overflow-x: hidden;
//       }
//       .input-file-label {
//         border: 1px solid #e0e0e0;
//         color: #0b6277;
//         background-color: #fff;
//         padding: 0 auto;
//         width: 100%;
//         height: 40px;
//         border-radius: 12px;
//         text-align: center;
//         font-size: 13px;
//         font-weight: 500;
//         padding-top: 14px;
//         margin-bottom: 12px;
//         &:hover {
//           border: 1px solid #0b6277;
//           color: #fff;
//           background-color: #0b6277;
//         }
//         .input-file-input {
//           display: none;
//         }
//       }
//       .service-form {
//         @include flex-column;
//         height: 100%;
//         > li {
//           position: relative;
//           + li {
//             margin-top: 10px;
//           }
//         }
//       }
//       .file-area {
//         border: 1px solid $color-sub2;
//         background: $color-white;
//       }
//       .file-area,
//       .form-contents-area {
//         flex: 1 1 auto;
//         overflow-y: auto;
//       }
//       .import-area {
//         canvas,
//         img {
//           width: 100%;
//           vertical-align: top;
//         }
//       }
//       .before-file,
//       .before-loading {
//         @include flex-all-center;
//         flex-direction: column;
//         height: 100%;
//         background: $color-white;
//       }
//       .sub-form {
//         .form-header {
//           @include flex-align-center;
//           position: relative;
//           border-radius: $md-radius;
//           padding: 10px 30px;
//           background: $color-point2;
//         }
//       }
//       // 복사 아이템
//       .linked-item {
//         position: relative;
//         background: $color-white;
//         padding: 30px;
//         border: {
//           width: 1px;
//           style: solid;
//           color: transparent;
//         }
//         + .linked-item {
//           margin-top: 10px;
//         }
//         .btn {
//           position: absolute;
//           top: 25px;
//           right: 30px;
//         }
//         &:hover {
//           border-color: $color-point1;
//         }
//         dt {
//           @include font-lg-b;
//           color: $color-black;
//           margin-bottom: 20px;
//         }
//         dd {
//           @include font-sm;
//           + dd {
//             margin-top: 5px;
//           }
//         }
//         &:not(.not-copy) {
//           dl {
//             padding-right: 80px;
//           }
//         }
//       }
//       .form-control {
//         @include flex-align-center;
//         justify-content: space-between;
//         .crop-btns {
//           border: 1px solid #e0e0e0;
//           color: #0b6277;
//           background-color: #fff;
//           padding: 0 auto;
//           width: 190px;
//           height: 40px;
//           border-radius: 12px;
//           text-align: center;
//           font-size: 13px;
//           font-weight: 500;
//           padding-top: 14px;
//           &:hover {
//             border: 1px solid #0b6277;
//             color: #fff;
//             background-color: #0b6277;
//           }
//         }
//         // .error-no {
//         //   @include inline-flex-all-center;
//         //   @include font-md-b;
//         //   color: $color-red;
//         //   background: $color-white;
//         //   border-radius: $md-radius;
//         //   border: 1px solid $color-sub2;
//         //   height: 40px;
//         //   flex: 0 0 25%;
//         // }
//         // .btn.light {
//         //   flex: 0 0 25%;
//         // }
//         // .btn.danger {
//         //   flex: 0 0 25%;
//         // }
//       }
//     }
//   }
// }

// @include lgDesktop {
//   .bl-upload-upsert-result {
//     margin: 30px auto;
//     max-width: 1600px;
//     min-width: 1600px;
//     @include flex-row;
//     // todo. 임시 => bl 업로드하는 영역을 최대로 할 필요성 있음
//     height: 700px;

//     #service-area {
//       @include flex-row;
//       height: 100%;
//       width: 100%;
//       #left-side {
//         @include flex-column;
//         display: flex;
//         flex: 1 1;
//       }
//       .main-section,
//       .sub-section {
//         @include flex-column;
//       }
//       .main-section {
//         display: flex;
//         overflow-x: hidden;
//         height: 100%;
//         background-color: #fff;
//       }
//       .sub-section {
//         flex: 1 1;
//         margin-left: 30px;
//         overflow-x: hidden;
//       }
//       .input-file-label {
//         border: 1px solid #e0e0e0;
//         color: #0b6277;
//         background-color: #fff;
//         padding: 0 auto;
//         width: 100%;
//         height: 40px;
//         border-radius: 12px;
//         text-align: center;
//         font-size: 13px;
//         font-weight: 500;
//         padding-top: 14px;
//         margin-bottom: 12px;
//         &:hover {
//           border: 1px solid #0b6277;
//           color: #fff;
//           background-color: #0b6277;
//         }
//         .input-file-input {
//           display: none;
//         }
//       }
//       .service-form {
//         @include flex-column;
//         height: 100%;
//         > li {
//           position: relative;
//           + li {
//             margin-top: 10px;
//           }
//         }
//       }
//       .file-area {
//         border: 1px solid $color-sub2;
//         background: $color-white;
//       }
//       .file-area,
//       .form-contents-area {
//         flex: 1 1 auto;
//         overflow-y: auto;
//       }
//       .import-area {
//         canvas,
//         img {
//           width: 100%;
//           vertical-align: top;
//         }
//       }
//       .before-file,
//       .before-loading {
//         @include flex-all-center;
//         flex-direction: column;
//         height: 100%;
//         background: $color-white;
//       }
//       .sub-form {
//         .form-header {
//           @include flex-align-center;
//           position: relative;
//           border-radius: $md-radius;
//           padding: 10px 30px;
//           background: $color-point2;
//         }
//       }
//       // 복사 아이템
//       .linked-item {
//         position: relative;
//         background: $color-white;
//         padding: 30px;
//         border: {
//           width: 1px;
//           style: solid;
//           color: transparent;
//         }
//         + .linked-item {
//           margin-top: 10px;
//         }
//         .btn {
//           position: absolute;
//           top: 25px;
//           right: 30px;
//         }
//         &:hover {
//           border-color: $color-point1;
//         }
//         dt {
//           @include font-lg-b;
//           color: $color-black;
//           margin-bottom: 20px;
//         }
//         dd {
//           @include font-sm;
//           + dd {
//             margin-top: 5px;
//           }
//         }
//         &:not(.not-copy) {
//           dl {
//             padding-right: 80px;
//           }
//         }
//       }
//       .form-control {
//         @include flex-align-center;
//         justify-content: space-between;
//         .crop-btns {
//           border: 1px solid #e0e0e0;
//           color: #0b6277;
//           background-color: #fff;
//           padding: 0 auto;
//           width: 190px;
//           height: 40px;
//           border-radius: 12px;
//           text-align: center;
//           font-size: 13px;
//           font-weight: 500;
//           padding-top: 14px;
//           &:hover {
//             border: 1px solid #0b6277;
//             color: #fff;
//             background-color: #0b6277;
//           }
//         }
//         // .error-no {
//         //   @include inline-flex-all-center;
//         //   @include font-md-b;
//         //   color: $color-red;
//         //   background: $color-white;
//         //   border-radius: $md-radius;
//         //   border: 1px solid $color-sub2;
//         //   height: 40px;
//         //   flex: 0 0 25%;
//         // }
//         // .btn.light {
//         //   flex: 0 0 25%;
//         // }
//         // .btn.danger {
//         //   flex: 0 0 25%;
//         // }
//       }
//     }
//   }
// }


// // @include lgDesktop {
// //   .bl-upload-upsert-result {
// //     margin: 30px auto;
// //     background-color: #fff;
// //     max-width: 1600px;
// //     min-width: 1600px;
// //     // 임시
// //     height: 700px;
// //     .bl-upload-upsert-left {
// //       width: 790px;
// //       margin-right: 10px;
// //       text-align: center;
// //       border: 1px solid #c5dade;
// //     }
// //     .bl-upload-upsert-right {
// //       width: 790px;
// //       background-color: #c5dade;
// //       margin-left: 10px;
// //       text-align: center;
// //     }
// //   }
// // }

//! -------condition 이랑, result랑 컴포넌트 나눈 버전

// #bl-upload-wrap {
//   background-color: #f5f5f5;
//   margin: 0 auto;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

//   #bl-upload-layout {
//     width: 1920px;
//     height: 1440px;
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr 1fr;
//     grid-template-rows: repeat(8, 1fr);

//     app-bl-upload-upsert-condition {
//       grid-column: 3/5;
//       grid-row: 1/2;
//       z-index: 100;

//       .bl-upload-upsert-btns {
//         @include flex-row;
//         min-width: 960px;
//         max-width: 960px;
//         margin-bottom: 20px;
//         justify-content: flex-end;
//         > button {
//           background-color: #0b6277;
//           color: #fff;
//           outline: none;
//           justify-content: flex-end;
//         }
//       }

//       .bl-upload-upsert-condition {
//         border: 1px solid #c5dade;
//         width: 100%;
//         margin: 0 auto;
//         font-size: 12px;
//         font-weight: 400;
//         .first-row, .second-row {
//           @include flex-row;
//           justify-content: space-between;
//           align-items: center;
//           flex-wrap: nowrap;
//           .search-th {
//             background: #f5f9fa;
//             color: #8eb3ba;
//             padding: 20px 0;
//             width: 25%;
//             text-align: center;
//             border-right: 1px solid #c5dade;
//             border-left: 1px solid #c5dade;
//             &:nth-child(1) {
//               border-left: none;
//             }
//           }
//           .search-td {
//             width: 19%;
//             background: none;
//             text-align: center;
//             margin: 0 auto;
//           }
//         }
//         .first-row {
//           border-bottom: 1px solid #c5dade;
//         }
//       }

//     }

//     app-bl-upload-upsert-result {
//       grid-column: 1/5;
//       grid-row: 1/9;

//       display: grid;
//       grid-template-columns: 1fr 1fr 1fr 1fr;
//       grid-template-rows: repeat(8, 1fr);

//     }

//     #left-side {
//       grid-column: 1/3;
//       grid-row: 1/9;
//       margin: 0 10px 0 0;

//       .zoom-btns { //! 임시
//         height: 2%;
//         > button {
//           color: #c5dade;
//           outline: none;
//           justify-content: flex-end;
//           border: 1px solid #c5dade;
//           background-color: #fff;
//           margin-right: 5px;
//         }
//       }
//       .main-section {
//         height: 98%;
//         text-align: center;
//         background-color: #fff;
//         margin-top: 5px;
//       }
//      .import-area {
//        canvas,
//        img {
//          width: 100%;
//          vertical-align: top;
//        }
//      }
//     }

//     #sub-section {
//       grid-column: 3/5;
//       grid-row: 2/9;

//       .crop-btns {
//         @include flex-row;
//         justify-content: end;
//         margin-bottom: 18px;
//         > button {
//         border: 1px solid #e0e0e0;
//         color: #0b6277;
//         background-color: #fff;
//         padding: 0 auto;
//         width: 190px;
//         height: 40px;
//         border-radius: 12px;
//         text-align: center;
//         font-size: 13px;
//         font-weight: 500;
//         padding-top: 8px;
//         margin-left: 5px;
//         &:hover {
//           border: 1px solid #0b6277;
//           color: #fff;
//           background-color: #0b6277;
//          }
//         }
//       }

//       .sub-section-cards {
//         overflow-y: auto;
//         height: 1210px;
//         .card-item {
//           border: 1px solid #e0e0e0;
//           background-color: #fff;
//           height: 100px;
//           margin-bottom: 8px;
//           padding: 13px;
//         }
//       }

//     }

//   }

//! -------condition 이랑, result랑 컴포넌트 합친 버전


  #bl-upload-wrap {
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #bl-upload-container {
    width: 90vw; // 2023.01.31 수정
    height: 100vh;
    @include flex-row;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-rows: repeat(8, 1fr);
    // 2023.01.26 수정
    #bl-upload-upsert-grid-wrap {
      width: 35%;
      height: 98vh; // 2023.01.27 수정
      // grid-column: 3/5;
      // grid-row: 1/2;
      // z-index: 100;

      .bl-upload-upsert-btns {
        @include flex-row; // 2023.01.26 수정
        // min-width: 960px;
        // max-width: 960px;
        width: 100%; // 2023.01.27 수정
        margin: 20px 0;
        justify-content: flex-end;
        > button {
          font-size: 16px;
          background-color: #0b6277;
          color: #fff;
          outline: none;
          justify-content: flex-end;
        }
      }

      .bl-upload-upsert-condition {
        width: 100%; // 2023.01.26 수정
        height: 386px;
        font-size: 12px;
        font-weight: 400;
        border: 1px solid #c5dade;
        .first-row, .second-row, .third-row, .fourth-row {
          width:100%;
          // @include flex-row;
          // justify-content: space-between;
          // align-items: center;
          // flex-wrap: nowrap;
          .search-th {
            background: #f5f9fa;
            color: #8eb3ba;
            height: 55px;
            line-height:55px;
            width: 25%;
            text-align: center;
            float: left;
            border-bottom: 1px solid #c5dade;
            border-right: 1px solid #c5dade;

          }
          .search-td {
            width: 70%;
            height:50px;
            background: none;
            text-align: center;
            float: right;
            margin-top: 7px;
            margin-right: 10px;
          }
        }
        .third-row {
          .search-td {
            margin-top: 6px;
          }
        }
        .fourth-row {
          .search-td {
            margin-top: 3px;
          }
        }
      }

    }

    #left-side {
      // grid-column: 1/3;
      // grid-row: 1/9;
      width:65%; // 2023.01.31 수정
      height:100vh;
      margin: 0 10px 0 0;

      .zoom-btns {
        width: 250px;
        height: 50px;
        padding: 17px 0;
        > button {
          width: 30px;
          height: 30px;
          line-height: 25px;
          color: $color-point2;
          outline: none;
          justify-content: flex-end;
          border: 1px solid $color-point2;
          background-color: #fff;
          margin-right: 5px;
          cursor: pointer;
          &:hover {
            color: $color-white;
            background-color: $color-point2;
          }
        }
        > button:nth-child(3),
        > button:nth-child(4) {
          width: 60px;
        } // 2023.01.27 수정
      }
      .main-section {
        width:100%;
        height: 92%; // 2023.01.31 수정
        text-align: center;
        background-color: #fff;
        margin-top: 5px;
      } // 2023.01.27 수정
     .import-area {
       canvas,
       img {
         width: 100%;
         vertical-align: top;
       }
     }
    }

    #sub-section {
      width: 100%;
      height: calc( 100vh - 42% ); // 2023.01.31 수정
      // grid-column: 3/5;
      // grid-row: 2/9;

      .crop-btns {
        @include flex-row;
        justify-content: flex-end;
        margin-bottom: 18px;
        > button {
        border: 1px solid #e0e0e0;
        color: #0b6277;
        background-color: #fff;
        padding: 0 auto;
        width: 190px;
        height: 40px;
        border-radius: 12px;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        padding-top: 8px;
        margin-left: 5px;
        &:hover {
          border: 1px solid #0b6277;
          color: #fff;
          background-color: #0b6277;
         }
        }
      }

      .sub-section-cards {
        clear: both;
        overflow-y: auto;
        height: calc( 100vh - 69% ); // 2023.01.31 수정
        padding-bottom: 15px; // 2023.01.26 추가
        .card-item {
          border: 1px solid #e0e0e0;
          background-color: #fff;
          margin-bottom: 8px;
          padding: 13px;
        }
        .invalid {
          border: 1px solid red;
        }
      }

    }

    }

    dl {
      display:flex;
      dt {
        width: 230px;
        font-size: 14px;
        margin-top: 7px;
      }
      dd {
        margin: 0 2.5px;
        button {
          width: 90px;
          span {
            font-size: 12px;
          }
        }
      }
    }

    // 2023 01.31 media 쿼리 추가
  @media all and ( max-width: 1920px ) {
    #bl-upload-container #sub-section {
      height: calc( 100vh - 500px );
      .sub-section-cards {
        height: calc( 100vh - 500px );
      }
    }

  }












