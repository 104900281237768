//? 1. btn-group ----------------------------------------------------------------

.ant-btn-group {

  position: relative;
  display: inline-flex;

  .ant-btn-primary {
    &:not(:first-child):not(:last-child) {
      border-right-color: #40a9ff;
      border-left-color: #40a9ff;
      &:disabled {
        border-color: #d9d9d9;
      }
    }
    &:first-child:not(:last-child) {
      border-right-color: #40a9ff;
      &:disabled {
        border-right-color: #d9d9d9;
      }
    }
    &:last-child:not(:first-child),
    + .ant-btn-primary {
      border-left-color: #40a9ff;
      &[disabled] {
        border-left-color: #d9d9d9;
      }
    }
    // TODO : 일단 주석처리 -> 아래 그룹에서 포함시켜서 다시한번 묶어주기 ===> done
    + .ant-btn:not(.ant-btn-primary):not([disabled]) {
      border-left-color: transparent;
    }
  }

  > {
    .ant-btn,
    span > .ant-btn {
      position: relative;
      &:hover,
      &:focus,
      &:active {
        z-index: 2;
      }
      &:disabled {
        z-index: 0;
      }
    }
    .ant-btn:first-child,
    span:first-child > .ant-btn {
      margin-left: 0;
    }
    .ant-btn:only-child,
    span:only-child > .ant-btn {
      border-radius: 2px;
    }
    .ant-btn:first-child:not(:last-child),
    span:first-child:not(:last-child) > .ant-btn {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    .ant-btn:last-child:not(:first-child),
    span:last-child:not(:first-child) > .ant-btn {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
    .ant-btn-group {
      float: left;
      &:not(:first-child):not(:last-child) > .ant-btn {
        border-radius: 0;
      }
      &:first-child:not(:last-child) > .ant-btn:last-child {
        padding-right: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child:not(:first-child) > .ant-btn:first-child {
        padding-left: 8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .ant-btn-icon-only {
    font-size: 14px;
  }

  .ant-btn + .ant-btn,
  span + .ant-btn,
  .ant-btn + span,
  > span + span,
  + .ant-btn,
  + .ant-btn-group {
    margin-left: -1px;
  }

  .ant-btn {
    border-radius: 0;
  }

  .ant-btn-group-rtl {
    direction: rtl;
  }

}

// 기타
.ant-dropdown-button.ant-btn-group {
  > {
    .ant-btn-loading {
      cursor: default;
      pointer-events: none;
      + .ant-btn {
        cursor: default;
        pointer-events: none;
        &::before {
          display: block;
        }
      }
    }
    .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
}

// TODO: 아래 3줄 갈길찾아가야함
.ant-btn + .ant-btn-group {
  margin-left: -1px;
}

//! original code
// btn-group > btn-primary
// .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
//   border-right-color: #40a9ff;
//   border-left-color: #40a9ff;
// }
// .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
//   border-color: #d9d9d9;
// }
// .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
//   border-right-color: #40a9ff;
// }
// .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
//   border-right-color: #d9d9d9;
// }
// .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
// .ant-btn-group .ant-btn-primary + .ant-btn-primary {
//   border-left-color: #40a9ff;
// }
// .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
// .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
//   border-left-color: #d9d9d9;
// }

// pure btn-group
// .ant-btn-group {
//   position: relative;
//   display: inline-flex;
// }

// TODO : 아래 3줄 맞는지 확인 => 63
// .ant-btn-group > .ant-btn,
// .ant-btn-group > span > .ant-btn {
//   position: relative;
// }
// .ant-btn-group > .ant-btn:hover,
// .ant-btn-group > span > .ant-btn:hover,
// .ant-btn-group > .ant-btn:focus,
// .ant-btn-group > span > .ant-btn:focus,
// .ant-btn-group > .ant-btn:active,
// .ant-btn-group > span > .ant-btn:active {
//   z-index: 2;
// }
// .ant-btn-group > .ant-btn[disabled],
// .ant-btn-group > span > .ant-btn[disabled] {
//   z-index: 0;
// }
// .ant-btn-group .ant-btn-icon-only {
//   font-size: 14px;
// }

// btn-group >
// .ant-btn-group .ant-btn + .ant-btn,
// .ant-btn + .ant-btn-group,
// .ant-btn-group span + .ant-btn,
// .ant-btn-group .ant-btn + span,
// .ant-btn-group > span + span,
// .ant-btn-group + .ant-btn,
// .ant-btn-group + .ant-btn-group {
//   margin-left: -1px;
// }
// .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
//  border-left-color: transparent;
// }
// .ant-btn-group .ant-btn {
//   border-radius: 0;
// }
// .ant-btn-group > .ant-btn:first-child,
// .ant-btn-group > span:first-child > .ant-btn {
//   margin-left: 0;
// }
// .ant-btn-group > .ant-btn:only-child {
//   border-radius: 2px;
// }
// .ant-btn-group > span:only-child > .ant-btn {
//   border-radius: 2px;
// }
// .ant-btn-group > .ant-btn:first-child:not(:last-child),
// .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
//   border-top-left-radius: 2px;
//   border-bottom-left-radius: 2px;
// }
// .ant-btn-group > .ant-btn:last-child:not(:first-child),
// .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
//   border-top-right-radius: 2px;
//   border-bottom-right-radius: 2px;
// }
// add
// .ant-btn-group > .ant-btn-group {
//   float: left;
// }
// .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
//   border-radius: 0;
// }
// .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
//   padding-right: 8px;
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
// }
// .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
//   padding-left: 8px;
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
// }

// .ant-btn-group.ant-btn-group-rtl {
//   direction: rtl;
// }

// 기타
// .ant-dropdown-button.ant-btn-group > .ant-btn-loading,
// .ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn {
//   cursor: default;
//   pointer-events: none;
// }
// .ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn::before {
//   display: block;
// }
// .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
//   padding-right: 8px;
//   padding-left: 8px;
// }

//? 2. btn-group-lg ----------------------------------------------------------------
.ant-btn-group-lg {
  > {
    .ant-btn,
    span > .ant-btn {
      height: 40px;
      padding: 6.4px 15px;
      font-size: 16px;
      border-radius: 0;
    }
  }
  .ant-btn .ant-btn-icon-only {
    width: 40px;
    height: 40px;
    padding-right: 0;
    padding-left: 0;
  }
}
//! original code
// .ant-btn-group-lg > .ant-btn,
// .ant-btn-group-lg > span > .ant-btn {
//   height: 40px;
//   padding: 6.4px 15px;
//   font-size: 16px;
//   border-radius: 0;
// }
// .ant-btn-group-lg .ant-btn.ant-btn-icon-only {
//   width: 40px;
//   height: 40px;
//   padding-right: 0;
//   padding-left: 0;
// }

//? 3. btn-group-sm ----------------------------------------------------------------

.ant-btn-group-sm {
  .ant-btn .ant-btn-icon-only {
    width: 24px;
    height: 24px;
    padding-right: 0;
    padding-left: 0;
  }
  > {
    .ant-btn,
    span > .ant-btn {
      height: 24px;
      padding: 0px 7px;
      font-size: 14px;
      border-radius: 0;
      > {
        .action {
          font-size: 14px;
        }
      }
    }
    .ant-btn:only-child,
    span:only-child > .ant-btn {
      border-radius: 2px;
    }
    .ant-btn:first-child:not(:last-child),
    span:first-child:not(:last-child) > .ant-btn {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    .ant-btn:last-child:not(:first-child),
    span:last-child:not(:first-child) > .ant-btn {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
}

//! original code
// .ant-btn-group-sm > .ant-btn,
// .ant-btn-group-sm > span > .ant-btn {
//   height: 24px;
//   padding: 0px 7px;
//   font-size: 14px;
//   border-radius: 0;
// }
// .ant-btn-group-sm > .ant-btn > .anticon,
// .ant-btn-group-sm > span > .ant-btn > .anticon {
//   font-size: 14px;
// }
// .ant-btn-group-sm .ant-btn.ant-btn-icon-only {
//   width: 24px;
//   height: 24px;
//   padding-right: 0;
//   padding-left: 0;
// }
// .ant-btn-group-sm > .ant-btn:only-child {
//   border-radius: 2px;
// }
// .ant-btn-group-sm > span:only-child > .ant-btn {
//   border-radius: 2px;
// }
// .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
// .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
//   border-top-left-radius: 2px;
//   border-bottom-left-radius: 2px;
// }
// .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
// .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
//   border-top-right-radius: 2px;
//   border-bottom-right-radius: 2px;
// }

//? 4. btn-group-rtl ----------------------------------------------------------------

.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}

.ant-btn-grou-rtl {
  > {
    .ant-btn:first-child:not(:last-child),
    span:first-child:not(:last-child) > .ant-btn {
      border-radius: 0 2px 2px 0;
    }
    .ant-btn:last-child:not(:first-child),
    span:last-child:not(:first-child) > .ant-btn {
      border-radius: 2px 0 0 2px;
    }
  }
  .ant-btn-group {
    .ant-btn-primary:last-child:not(:first-child),
    .ant-btn-primary + .ant-btn-primary {
      border-right-color: #40a9ff;
      border-left-color: #d9d9d9;
      &:disabled {
        border-right-color: #d9d9d9;
        border-left-color: #40a9ff;
      }
    }
  }
  .ant-btn-group-sm {
    > {
      .ant-btn:first-child:not(:last-child),
      span:first-child:not(:last-child) > .ant-btn {
        border-radius: 0 2px 2px 0;
      }
      .ant-btn:last-child:not(:first-child),
      span:last-child:not(:first-child) > .ant-btn {
        border-radius: 2px 0 0 2px;
      }
    }
  }
}

//! original code
// btn-group-rtl && btn-rtl (일부)
// .ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
// .ant-btn-rtl.ant-btn + .ant-btn-group,
// .ant-btn-rtl.ant-btn-group span + .ant-btn,
// .ant-btn-rtl.ant-btn-group .ant-btn + span,
// .ant-btn-rtl.ant-btn-group > span + span,
// .ant-btn-rtl.ant-btn-group + .ant-btn,
// .ant-btn-rtl.ant-btn-group + .ant-btn-group,
// .ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
// .ant-btn-group-rtl.ant-btn + .ant-btn-group,
// .ant-btn-group-rtl.ant-btn-group span + .ant-btn,
// .ant-btn-group-rtl.ant-btn-group .ant-btn + span,
// .ant-btn-group-rtl.ant-btn-group > span + span,
// .ant-btn-group-rtl.ant-btn-group + .ant-btn,
// .ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
//   margin-right: -1px;
//   margin-left: auto;
// }

// .ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
// .ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
//   border-radius: 0 2px 2px 0;
// }
// .ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
// .ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
//   border-radius: 2px 0 0 2px;
// }
// .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
// .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
//   border-right-color: #40a9ff;
//   border-left-color: #d9d9d9;
// }
// .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
// .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
//   border-right-color: #d9d9d9;
//   border-left-color: #40a9ff;
// }
// .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
// .ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
//   border-radius: 0 2px 2px 0;
// }
// .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
// .ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
//   border-radius: 2px 0 0 2px;
// }


// TODO. [shared / button / button.ts ] ///////////////////////////////////////

//? 1. btn----------------------------------------------------------------

.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  outline: 0;

  > {
    .anticon {
      line-height: 1;
    }
    a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
    span {
      display: inline-block;
    }
  }

  :not([disabled]) {
    &:hover {
      text-decoration: none;
    }
    &:active {
      outline: 0;
      box-shadow: none;
    }
  }

  &:disabled {
    cursor: not-allowed;
    > * {
      pointer-events: none;
    }
  }

  &:focus {
    outline: 0;
    text-decoration: none;
    background: #fff;
  }

  &:hover,
  &:focus {
    color: #0b6277;
    border-color: #40a9ff;
    background: #fff;
    text-decoration: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
  }

  &:active {
    outline: 0;
    color: #096dd9;
    border-color: #096dd9;
    background: #fff;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
  }

  // TODO : disabled 다시 정리하기 >_<

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    &:hover, &:focus, &:active {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
    }
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
      &:hover, &:focus, &:active {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
    // > a:only-child, &:hover > a:only-child, &:focus > a:only-child, &:active > a:only-child {
    //   color: currentcolor;
    // }
  }

  &::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: #fff;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: '';
    pointer-events: none;
  }

  .anticon {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    .anticon-plus > svg,
    .anticon-minus > svg {
      shape-rendering: optimizespeed;
    }
  }

  .ant-btn-loading {
    position: relative;
    cursor: default;
    &::before {
      display: block;
    }
  }

  > .ant-btn-loading-icon {
    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    .anticon {
      padding-right: 8px;
      animation: none;
      svg {
        animation: loadingCircle 1s infinite linear;
      }
    }
    &:only-child .anticon {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &:focus > span,
  &:active > span {
    position: relative;
  }

  > {
    .anticon + span,
    span + .anticon {
      margin-left: 8px;
    }
  }

  .ant-btn-background-ghost {
    color: #fff;
    border-color: #fff;
    &:hover,
    &:focus {
      background: transparent;
      color: #40a9ff;
      border-color: #40a9ff;
    }
    &:active {
      background: transparent;
      color: #096dd9;
      border-color: #096dd9;
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      background: transparent;
      border-color: #d9d9d9;
    }
  }

  .ant-btn-block {
    width: 100%;
  }

  &:empty {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '\a0';
  }

}

a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 30px;
}


//! original code
// .ant-btn {
//   line-height: 1.5715;
//   position: relative;
//   display: inline-block;
//   font-weight: 400;
//   white-space: nowrap;
//   text-align: center;
//   background-image: none;
//   border: 1px solid transparent;
//   box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
//   cursor: pointer;
//   transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//   user-select: none;
//   touch-action: manipulation;
//   height: 32px;
//   padding: 4px 15px;
//   font-size: 14px;
//   border-radius: 2px;
//   color: rgba(0, 0, 0, 0.85);
//   border-color: #d9d9d9;
//   background: #fff;
// }

// .ant-btn > .anticon {
//   line-height: 1;
// }
// .ant-btn,
// TODO : 아래 효과 더블 체크
// .ant-btn:active,
// .ant-btn:focus {
//   outline: 0;
// }

// .ant-btn:not([disabled]):hover {
//   text-decoration: none;
// }
// .ant-btn:not([disabled]):active {
//   outline: 0;
//   box-shadow: none;
// }
// .ant-btn[disabled] {
//   cursor: not-allowed;
// }
// .ant-btn[disabled] > * {
//   pointer-events: none;
// }
// .ant-btn > a:only-child {
//   color: currentcolor;
// }
// .ant-btn > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }]
// TODO. 아래 효과 더블체크
// .ant-btn:hover,
// .ant-btn:focus {
//   color: #0b6277;
//   border-color: #40a9ff;
//   background: #fff;
// }

// .ant-btn:hover > a:only-child,
// .ant-btn:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn:hover > a:only-child::after,
// .ant-btn:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn:active {
//   color: #096dd9;
//   border-color: #096dd9;
//   background: #fff;
// }
// .ant-btn:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn[disabled],
// .ant-btn[disabled]:hover,
// .ant-btn[disabled]:focus,
// .ant-btn[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn[disabled] > a:only-child,
// .ant-btn[disabled]:hover > a:only-child,
// .ant-btn[disabled]:focus > a:only-child,
// .ant-btn[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn[disabled] > a:only-child::after,
// .ant-btn[disabled]:hover > a:only-child::after,
// .ant-btn[disabled]:focus > a:only-child::after,
// .ant-btn[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn:hover,
// .ant-btn:focus,
// .ant-btn:active {
//   text-decoration: none;
//   background: #fff;
// }
// .ant-btn > span {
//   display: inline-block;
// }

// .ant-btn::before {
//   position: absolute;
//   top: -1px;
//   right: -1px;
//   bottom: -1px;
//   left: -1px;
//   z-index: 1;
//   display: none;
//   background: #fff;
//   border-radius: inherit;
//   opacity: 0.35;
//   transition: opacity 0.2s;
//   content: '';
//   pointer-events: none;
// }
// .ant-btn .anticon {
//   transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
// }
// .ant-btn .anticon.anticon-plus > svg,
// .ant-btn .anticon.anticon-minus > svg {
//   shape-rendering: optimizespeed;
// }
// .ant-btn .ant-btn-loading {
//   position: relative;
//   cursor: default;
// }
// .ant-btn .ant-btn-loading::before {
//   display: block;
// }
// .ant-btn > .ant-btn-loading-icon {
//   transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
// }
// .ant-btn > .ant-btn-loading-icon .anticon {
//   padding-right: 8px;
//   animation: none;
// }
// .ant-btn > .ant-btn-loading-icon .anticon svg {
//   animation: loadingCircle 1s infinite linear;
// }
// .ant-btn > .ant-btn-loading-icon:only-child .anticon {
//   padding-right: 0;
// }
// .ant-btn:focus > span,
// .ant-btn:active > span {
//   position: relative;
// }
// .ant-btn > .anticon + span,
// .ant-btn > span + .anticon {
//   margin-left: 8px;
// }
// .ant-btn.ant-btn-background-ghost {
//   color: #fff;
//   border-color: #fff;
// }
// .ant-btn.ant-btn-background-ghost,
// .ant-btn.ant-btn-background-ghost:hover,
// .ant-btn.ant-btn-background-ghost:active,
// .ant-btn.ant-btn-background-ghost:focus {
//   background: transparent;
// }
// .ant-btn.ant-btn-background-ghost:hover,
// .ant-btn.ant-btn-background-ghost:focus {
//   color: #40a9ff;
//   border-color: #40a9ff;
// }
// .ant-btn.ant-btn-background-ghost:active {
//   color: #096dd9;
//   border-color: #096dd9;
// }
// .ant-btn.ant-btn-background-ghost[disabled] {
//   color: rgba(0, 0, 0, 0.25);
//   background: transparent;
//   border-color: #d9d9d9;
// }
// .ant-btn.ant-btn-block {
//   width: 100%;
// }
// .ant-btn:empty {
//   display: inline-block;
//   width: 0;
//   visibility: hidden;
//   content: '\a0';
// }
// a.ant-btn {
//   padding-top: 0.01px !important;
//   line-height: 30px;
// }
// .ant-btn > .ant-btn-loading-icon:only-child .anticon {
//   padding-right: 0;
//   padding-left: 0;
// }

//* 기타 (미사용 - 주석처리) ----------------------------------------
// .ant-card-actions > li > span a:not(.ant-btn),
// .ant-card-actions > li > span > .anticon {
//   display: inline-block;
//   width: 100%;
//   color: rgba(0, 0, 0, 0.45);
//   line-height: 22px;
//   transition: color 0.3s;
// }
// .ant-card-actions > li > span a:not(.ant-btn):hover,
// .ant-card-actions > li > span > .anticon:hover {
//   color: #1890ff;
// }
// .ant-dropdown-wrap .ant-btn > .anticon-down {
//   font-size: 10px;
// }
// .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
//   margin-bottom: 0;
//   margin-left: 8px;
// }
// .ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
//   margin-bottom: 0;
//   margin-left: 8px;
// }
// .ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
//   margin-right: 8px;
//   margin-left: 0;
// }
// .ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
//   margin-right: 8px;
//   margin-left: 0;
// }
// .ant-transfer-operation .ant-btn {
//   display: block;
// }
// .ant-transfer-operation .ant-btn:first-child {
//   margin-bottom: 4px;
// }
// .ant-transfer-operation .ant-btn .anticon {
//   font-size: 12px;
// }
//* 미사용-주석처리 여기까지 ----------------------------------------



//? 2. btn-primary----------------------------------------------------------------
.ant-btn-primary {
  color: #fff;
  border-color: #0b6277;
  background: #0b6277;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

  > a:only-child {
    color: currentcolor;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent;
      content: '';
    }
  }

  &:hover, &:focus {
    color: #fff;
    border-color: #0b6277;
    background: #0b6277;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
  }

  &:active {
    color: #fff;
    border-color: #096dd9;
    background: #096dd9;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    &:hover, &:focus, &:active {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
  }

}

//! original code
// .ant-btn-primary {
//   color: #fff;
//   border-color: #0b6277;
//   background: #0b6277;
//   text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
//   box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
// }
// .ant-btn-primary > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-primary > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-primary:hover,
// .ant-btn-primary:focus {
//   color: #fff;
//   border-color: #0b6277;
//   background: #0b6277;
// }
// .ant-btn-primary:hover > a:only-child,
// .ant-btn-primary:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-primary:hover > a:only-child::after,
// .ant-btn-primary:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-primary:active {
//   color: #fff;
//   border-color: #096dd9;
//   background: #096dd9;
// }
// .ant-btn-primary:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-primary:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-primary[disabled],
// .ant-btn-primary[disabled]:hover,
// .ant-btn-primary[disabled]:focus,
// .ant-btn-primary[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-primary[disabled] > a:only-child,
// .ant-btn-primary[disabled]:hover > a:only-child,
// .ant-btn-primary[disabled]:focus > a:only-child,
// .ant-btn-primary[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-primary[disabled] > a:only-child::after,
// .ant-btn-primary[disabled]:hover > a:only-child::after,
// .ant-btn-primary[disabled]:focus > a:only-child::after,
// .ant-btn-primary[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }

//? 3. btn-dashed----------------------------------------------------------------
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  border-style: dashed;

  > a:only-child {
    color: currentcolor;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent;
      content: '';
    }
  }

  &:hover,
  &:focus {
    color: #0b6277;
    border-color: #0b6277;
    background: #fff;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
  }

  &:active {
    color: #096dd9;
    border-color: #096dd9;
    background: #fff;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }

    &:hover, &:focus, &:active {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
  }

}

//! original code
// .ant-btn-dashed {
//   color: rgba(0, 0, 0, 0.85);
//   border-color: #d9d9d9;
//   background: #fff;
//   border-style: dashed;
// }
// .ant-btn-dashed > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dashed > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dashed:hover,
// .ant-btn-dashed:focus {
//   color: #0b6277;
//   border-color: #0b6277;
//   background: #fff;
// }
// .ant-btn-dashed:hover > a:only-child,
// .ant-btn-dashed:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dashed:hover > a:only-child::after,
// .ant-btn-dashed:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dashed:active {
//   color: #096dd9;
//   border-color: #096dd9;
//   background: #fff;
// }
// .ant-btn-dashed:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dashed:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dashed[disabled],
// .ant-btn-dashed[disabled]:hover,
// .ant-btn-dashed[disabled]:focus,
// .ant-btn-dashed[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-dashed[disabled] > a:only-child,
// .ant-btn-dashed[disabled]:hover > a:only-child,
// .ant-btn-dashed[disabled]:focus > a:only-child,
// .ant-btn-dashed[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dashed[disabled] > a:only-child::after,
// .ant-btn-dashed[disabled]:hover > a:only-child::after,
// .ant-btn-dashed[disabled]:focus > a:only-child::after,
// .ant-btn-dashed[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }

//? 4. btn-link----------------------------------------------------------------
.ant-btn-link {
  color: #0b6277;
  border-color: transparent;
  background: transparent;
  box-shadow: none;

  > a:only-child {
    color: currentcolor;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent;
      content: '';
    }
  }

  &:hover, &:focus {
    color: #40a9ff;
    border-color: #0b6277;
    background: transparent;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
  }

  &:active {
    color: #096dd9;
    border-color: #096dd9;
    background: transparent;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
    &:hover, &:focus, &:active {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
  }

  &:hover {
    background: transparent;
    border-color: transparent;
  }

  &:focus, &:active {
    border-color: transparent;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }

    &:hover, &:focus, &:active {
      color: rgba(0, 0, 0, 0.25);
      border-color: transparent;
      background: transparent;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
  }

}

//! original code
// .ant-btn-link {
//   color: #0b6277;
//   border-color: transparent;
//   background: transparent;
//   box-shadow: none;
// }
// .ant-btn-link > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-link > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-link:hover,
// .ant-btn-link:focus {
//   color: #40a9ff;
//   border-color: #0b6277;
//   background: transparent;
// }
// .ant-btn-link:hover > a:only-child,
// .ant-btn-link:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-link:hover > a:only-child::after,
// .ant-btn-link:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-link:active {
//   color: #096dd9;
//   border-color: #096dd9;
//   background: transparent;
// }
// .ant-btn-link:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-link:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-link[disabled],
// .ant-btn-link[disabled]:hover,
// .ant-btn-link[disabled]:focus,
// .ant-btn-link[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-link[disabled] > a:only-child,
// .ant-btn-link[disabled]:hover > a:only-child,
// .ant-btn-link[disabled]:focus > a:only-child,
// .ant-btn-link[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-link[disabled] > a:only-child::after,
// .ant-btn-link[disabled]:hover > a:only-child::after,
// .ant-btn-link[disabled]:focus > a:only-child::after,
// .ant-btn-link[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-link:hover {
//   background: transparent;
// }
// .ant-btn-link:hover,
// .ant-btn-link:focus,
// .ant-btn-link:active {
//   border-color: transparent;
// }
// .ant-btn-link[disabled],
// .ant-btn-link[disabled]:hover,
// .ant-btn-link[disabled]:focus,
// .ant-btn-link[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: transparent;
//   background: transparent;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-link[disabled] > a:only-child,
// .ant-btn-link[disabled]:hover > a:only-child,
// .ant-btn-link[disabled]:focus > a:only-child,
// .ant-btn-link[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-link[disabled] > a:only-child::after,
// .ant-btn-link[disabled]:hover > a:only-child::after,
// .ant-btn-link[disabled]:focus > a:only-child::after,
// .ant-btn-link[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }

//? 5. btn-text----------------------------------------------------------------
.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  border-color: transparent;
  background: transparent;
  box-shadow: none;

  > a:only-child {
    color: currentcolor;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent;
      content: '';
    }
  }

  &:hover, &:focus {
    color: #0b6277;
    border-color: #40a9ff;
    background: transparent;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
  }

  &:active {
    color: #096dd9;
    border-color: #096dd9;
    background: transparent;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
    &:hover, &:focus, &:active {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
  }

  &:hover, &:focus {
    color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0.018);
    border-color: transparent;
  }

  &:active {
    color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0.028);
    border-color: transparent;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }

    &:hover, &:focus, &:active {
      color: rgba(0, 0, 0, 0.25);
      border-color: transparent;
      background: transparent;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
  }

}

//! original code
// .ant-btn-text {
//   color: rgba(0, 0, 0, 0.85);
//   border-color: transparent;
//   background: transparent;
//   box-shadow: none;
// }
// .ant-btn-text > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-text > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-text:hover,
// .ant-btn-text:focus {
//   color: #0b6277;
//   border-color: #40a9ff;
//   background: transparent;
// }
// .ant-btn-text:hover > a:only-child,
// .ant-btn-text:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-text:hover > a:only-child::after,
// .ant-btn-text:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-text:active {
//   color: #096dd9;
//   border-color: #096dd9;
//   background: transparent;
// }
// .ant-btn-text:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-text:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-text[disabled],
// .ant-btn-text[disabled]:hover,
// .ant-btn-text[disabled]:focus,
// .ant-btn-text[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-text[disabled] > a:only-child,
// .ant-btn-text[disabled]:hover > a:only-child,
// .ant-btn-text[disabled]:focus > a:only-child,
// .ant-btn-text[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-text[disabled] > a:only-child::after,
// .ant-btn-text[disabled]:hover > a:only-child::after,
// .ant-btn-text[disabled]:focus > a:only-child::after,
// .ant-btn-text[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-text:hover,
// .ant-btn-text:focus {
//   color: rgba(0, 0, 0, 0.85);
//   background: rgba(0, 0, 0, 0.018);
//   border-color: transparent;
// }
// .ant-btn-text:active {
//   color: rgba(0, 0, 0, 0.85);
//   background: rgba(0, 0, 0, 0.028);
//   border-color: transparent;
// }
// .ant-btn-text[disabled],
// .ant-btn-text[disabled]:hover,
// .ant-btn-text[disabled]:focus,
// .ant-btn-text[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: transparent;
//   background: transparent;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-text[disabled] > a:only-child,
// .ant-btn-text[disabled]:hover > a:only-child,
// .ant-btn-text[disabled]:focus > a:only-child,
// .ant-btn-text[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-text[disabled] > a:only-child::after,
// .ant-btn-text[disabled]:hover > a:only-child::after,
// .ant-btn-text[disabled]:focus > a:only-child::after,
// .ant-btn-text[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }

//? 6. btn-circle----------------------------------------------------------------
.ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
  .ant-btn-lg {
    min-width: 40px;
    border-radius: 50%;
  }
  .ant-btn-sm {
    min-width: 24px;
    border-radius: 50%;
  }
}

//? 7. btn-round----------------------------------------------------------------
.ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
  .ant-btn-lg {
    height: 40px;
    padding: 6.4px 20px;
    font-size: 16px;
    border-radius: 40px;
  }
  .ant-btn-sm {
    height: 24px;
    padding: 0px 12px;
    font-size: 14px;
    border-radius: 24px;
  }
  .ant-btn-icon-only {
    width: auto;
  }
}

//! original code
// .ant-btn-round.ant-btn-lg {
//   height: 40px;
//   padding: 6.4px 20px;
//   font-size: 16px;
//   border-radius: 40px;
// }
// .ant-btn-round.ant-btn-sm {
//   height: 24px;
//   padding: 0px 12px;
//   font-size: 14px;
//   border-radius: 24px;
// }
// .ant-btn-round.ant-btn-icon-only {
//   width: auto;
// }

//? 8. btn-lg----------------------------------------------------------------
.ant-btn-lg {
  height: 30px; // 2023.01.26 수정
  padding: 0 15px;
  font-size: 16px;
  border-radius: 2px;
}
a.ant-btn-lg {
  line-height: 32px; // 2023.01.26 수정
}

//? 9. btn-sm----------------------------------------------------------------
.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 20px;
  line-height: 1;
}

//? 10. btn-dangerous----------------------------------------------------------------
.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  background: #fff;

  > a:only-child {
    color: currentcolor;
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: transparent;
      content: '';
    }
  }

  &:hover, &:focus {
    color: #ff7875;
    border-color: #ff7875;
    background: #fff;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
  }

  &:active {
    color: #d9363e;
    border-color: #d9363e;
    background: #fff;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
    &:hover, &:focus, &:active {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
  }

  &.ant-btn-primary {
    color: #fff;
    border-color: #ff4d4f;
    background: #ff4d4f;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
    &:hover, &:focus {
      color: #fff;
      border-color: #ff7875;
      background: #ff7875;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }

    &:active {
      color: #fff;
      border-color: #d9363e;
      background: #d9363e;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
    &:hover, &:focus, &:active {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
  }

  &.ant-btn-link {
    color: #ff4d4f;
    border-color: transparent;
    background: transparent;
    box-shadow: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
    &:hover, &:focus {
      color: #40a9ff;
      border-color: #40a9ff;
      background: transparent;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }

    &:active {
      color: #096dd9;
      border-color: #096dd9;
      background: transparent;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }


    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
      &:hover, &:focus, &:active {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
        > a:only-child {
          color: currentcolor;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            content: '';
          }
        }
      }
    }

    &:hover, &:focus {
      color: #ff7875;
      border-color: transparent;
      background: transparent;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }

    &:active {
      color: #d9363e;
      border-color: transparent;
      background: transparent;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      border-color: transparent;
      background: transparent;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
      &:hover, &:focus, &:active {
        color: rgba(0, 0, 0, 0.25);
        border-color: transparent;
        background: transparent;
        text-shadow: none;
        box-shadow: none;
        > a:only-child {
          color: currentcolor;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            content: '';
          }
        }
      }
    }
  }

  &.ant-btn-text {
    color: #ff4d4f;
    border-color: transparent;
    background: transparent;
    box-shadow: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
    &:hover, &:focus {
      color: #40a9ff;
      border-color: #40a9ff;
      background: transparent;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
    &:active {
      color: #096dd9;
      border-color: #096dd9;
      background: transparent;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
      &:hover, &:focus, &:active {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
        > a:only-child {
          color: currentcolor;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            content: '';
          }
        }
      }
    }
    &:hover, &:focus {
      color: #ff7875;
      border-color: transparent;
      background: rgba(0, 0, 0, 0.018);
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
    &:active {
      color: #d9363e;
      border-color: transparent;
      background: rgba(0, 0, 0, 0.028);
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      border-color: transparent;
      background: transparent;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
      &:hover, &:focus, &:active {
        color: rgba(0, 0, 0, 0.25);
        border-color: transparent;
        background: transparent;
        text-shadow: none;
        box-shadow: none;
        > a:only-child {
          color: currentcolor;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            content: '';
          }
        }
      }
    }
  }

}

//! original code
// .ant-btn-dangerous {
//   color: #ff4d4f;
//   border-color: #ff4d4f;
//   background: #fff;
// }
// .ant-btn-dangerous > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous:hover,
// .ant-btn-dangerous:focus {
//   color: #ff7875;
//   border-color: #ff7875;
//   background: #fff;
// }
// .ant-btn-dangerous:hover > a:only-child,
// .ant-btn-dangerous:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous:hover > a:only-child::after,
// .ant-btn-dangerous:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous:active {
//   color: #d9363e;
//   border-color: #d9363e;
//   background: #fff;
// }
// .ant-btn-dangerous:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous[disabled],
// .ant-btn-dangerous[disabled]:hover,
// .ant-btn-dangerous[disabled]:focus,
// .ant-btn-dangerous[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-dangerous[disabled] > a:only-child,
// .ant-btn-dangerous[disabled]:hover > a:only-child,
// .ant-btn-dangerous[disabled]:focus > a:only-child,
// .ant-btn-dangerous[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous[disabled] > a:only-child::after,
// .ant-btn-dangerous[disabled]:hover > a:only-child::after,
// .ant-btn-dangerous[disabled]:focus > a:only-child::after,
// .ant-btn-dangerous[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-primary {
//   color: #fff;
//   border-color: #ff4d4f;
//   background: #ff4d4f;
//   text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
//   box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
// }
// .ant-btn-dangerous.ant-btn-primary > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-primary > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-primary:hover,
// .ant-btn-dangerous.ant-btn-primary:focus {
//   color: #fff;
//   border-color: #ff7875;
//   background: #ff7875;
// }
// .ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
// .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
// .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-primary:active {
//   color: #fff;
//   border-color: #d9363e;
//   background: #d9363e;
// }
// .ant-btn-dangerous.ant-btn-primary:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-primary[disabled],
// .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
// .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
// .ant-btn-dangerous.ant-btn-primary[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
// .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
// .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
// .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
// .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
// .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
// .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-link {
//   color: #ff4d4f;
//   border-color: transparent;
//   background: transparent;
//   box-shadow: none;
// }
// .ant-btn-dangerous.ant-btn-link > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-link > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-link:hover,
// .ant-btn-dangerous.ant-btn-link:focus {
//   color: #40a9ff;
//   border-color: #40a9ff;
//   background: transparent;
// }
// .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
// .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
// .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-link:active {
//   color: #096dd9;
//   border-color: #096dd9;
//   background: transparent;
// }
// .ant-btn-dangerous.ant-btn-link:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-link[disabled],
// .ant-btn-dangerous.ant-btn-link[disabled]:hover,
// .ant-btn-dangerous.ant-btn-link[disabled]:focus,
// .ant-btn-dangerous.ant-btn-link[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
// .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
// .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
// .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
// .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
// .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
// .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-link:hover,
// .ant-btn-dangerous.ant-btn-link:focus {
//   color: #ff7875;
//   border-color: transparent;
//   background: transparent;
// }
// .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
// .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
// .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-link:active {
//   color: #d9363e;
//   border-color: transparent;
//   background: transparent;
// }
// .ant-btn-dangerous.ant-btn-link:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-link[disabled],
// .ant-btn-dangerous.ant-btn-link[disabled]:hover,
// .ant-btn-dangerous.ant-btn-link[disabled]:focus,
// .ant-btn-dangerous.ant-btn-link[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: transparent;
//   background: transparent;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
// .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
// .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
// .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
// .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
// .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
// .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-text {
//   color: #ff4d4f;
//   border-color: transparent;
//   background: transparent;
//   box-shadow: none;
// }
// .ant-btn-dangerous.ant-btn-text > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-text > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-text:hover,
// .ant-btn-dangerous.ant-btn-text:focus {
//   color: #40a9ff;
//   border-color: #40a9ff;
//   background: transparent;
// }
// .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
// .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
// .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-text:active {
//   color: #096dd9;
//   border-color: #096dd9;
//   background: transparent;
// }
// .ant-btn-dangerous.ant-btn-text:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-text[disabled],
// .ant-btn-dangerous.ant-btn-text[disabled]:hover,
// .ant-btn-dangerous.ant-btn-text[disabled]:focus,
// .ant-btn-dangerous.ant-btn-text[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
// .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
// .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
// .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
// .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
// .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
// .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-text:hover,
// .ant-btn-dangerous.ant-btn-text:focus {
//   color: #ff7875;
//   border-color: transparent;
//   background: rgba(0, 0, 0, 0.018);
// }
// .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
// .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
// .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-text:active {
//   color: #d9363e;
//   border-color: transparent;
//   background: rgba(0, 0, 0, 0.028);
// }
// .ant-btn-dangerous.ant-btn-text:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-dangerous.ant-btn-text[disabled],
// .ant-btn-dangerous.ant-btn-text[disabled]:hover,
// .ant-btn-dangerous.ant-btn-text[disabled]:focus,
// .ant-btn-dangerous.ant-btn-text[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: transparent;
//   background: transparent;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
// .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
// .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
// .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
// .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
// .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
// .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }

//? 11. btn-loading----------------------------------------------------------------
// with btn

//? 12. btn-background-ghost----------------------------------------------------------------

.ant-btn-background-ghost {

  //! box custom
  &.ant-btn-primary {
    // color: #1890ff;
    color: #fff;
    border-color: #1890ff;
    text-shadow: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
    &:hover, &:focus {
      color: #40a9ff;
      border-color: #40a9ff;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
    &:active {
      color: #096dd9;
      border-color: #096dd9;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
      &:hover, &:focus, &:active {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
        > a:only-child {
          color: currentcolor;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            content: '';
          }
        }
      }
    }
  }

  &.ant-btn-danger {
    color: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
    &:hover, &:focus {
      color: #ff7875;
      border-color: #ff7875;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
    &:active {
      color: #d9363e;
      border-color: #d9363e;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
      &:hover, &:focus, &:active {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
        > a:only-child {
          color: currentcolor;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            content: '';
          }
        }
      }
    }
  }

  &.ant-btn-dangerous {
    color: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: none;
    > a:only-child {
      color: currentcolor;
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        content: '';
      }
    }
    &:hover, &:focus {
      color: #ff7875;
      border-color: #ff7875;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
    &:active {
      color: #d9363e;
      border-color: #d9363e;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background: #f5f5f5;
      text-shadow: none;
      box-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
      &:hover, &:focus, &:active {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
        > a:only-child {
          color: currentcolor;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            content: '';
          }
        }
      }
    }
    &.ant-btn-link {
      color: #ff4d4f;
      border-color: transparent;
      text-shadow: none;
      > a:only-child {
        color: currentcolor;
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: transparent;
          content: '';
        }
      }
      &:hover, &:focus {
        color: #ff7875;
        border-color: transparent;
        > a:only-child {
          color: currentcolor;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            content: '';
          }
        }
      }
      &:active {
        color: #d9363e;
        border-color: transparent;
        > a:only-child {
          color: currentcolor;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            content: '';
          }
        }
      }
      &:disabled {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
        > a:only-child {
          color: currentcolor;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            content: '';
          }
        }
        &:hover, &:focus, &:active {
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          background: #f5f5f5;
          text-shadow: none;
          box-shadow: none;
          > a:only-child {
            color: currentcolor;
            &::after {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background: transparent;
              content: '';
            }
          }
        }
        &:hover, &:focus, &:active {
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          background: #f5f5f5;
          text-shadow: none;
          box-shadow: none;
        }
        > a:only-child, &:hover > a:only-child, &:focus > a:only-child, &:active > a:only-child {
          color: currentcolor;
          &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: transparent;
            content: '';
          }
        }
      }
    }
  }
}

//! original code
// .ant-btn-background-ghost.ant-btn-primary {
//   // color: #1890ff;
//   color: #fff;
//   border-color: #1890ff;
//   text-shadow: none;
// }
// .ant-btn-background-ghost.ant-btn-primary > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-primary:hover,
// .ant-btn-background-ghost.ant-btn-primary:focus {
//   color: #40a9ff;
//   border-color: #40a9ff;
// }
// .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
// .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-primary:active {
//   color: #096dd9;
//   border-color: #096dd9;
// }
// .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-primary[disabled],
// .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
// .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
// .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
// .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
// .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
// .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-danger {
//   color: #ff4d4f;
//   border-color: #ff4d4f;
//   text-shadow: none;
// }
// .ant-btn-background-ghost.ant-btn-danger > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-danger:hover,
// .ant-btn-background-ghost.ant-btn-danger:focus {
//   color: #ff7875;
//   border-color: #ff7875;
// }
// .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
// .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-danger:active {
//   color: #d9363e;
//   border-color: #d9363e;
// }
// .ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-danger[disabled],
// .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
// .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
// .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
// .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
// .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
// .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-dangerous {
//   color: #ff4d4f;
//   border-color: #ff4d4f;
//   text-shadow: none;
// }
// .ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-dangerous:hover,
// .ant-btn-background-ghost.ant-btn-dangerous:focus {
//   color: #ff7875;
//   border-color: #ff7875;
// }
// .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
// .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-dangerous:active {
//   color: #d9363e;
//   border-color: #d9363e;
// }
// .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-dangerous[disabled],
// .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
// .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
// .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }

// .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
// .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
// .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
// .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
//   color: #ff4d4f;
//   border-color: transparent;
//   text-shadow: none;
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
//   color: #ff7875;
//   border-color: transparent;
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
//   color: #d9363e;
//   border-color: transparent;
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }

// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
//   color: rgba(0, 0, 0, 0.25);
//   border-color: #d9d9d9;
//   background: #f5f5f5;
//   text-shadow: none;
//   box-shadow: none;
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
//   color: currentcolor;
// }
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
// .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background: transparent;
//   content: '';
// }

//? 13. btn-block----------------------------------------------------------------
// with btn

//? 14. input-search-button----------------------------------------------------------------

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}

.ant-input-search {

  .ant-input {
    &:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
    &:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
      border-left-color: #40a9ff;
    }
  }

  > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0 2px 2px 0;
    &:not(.ant-btn-primary) {
      color: rgba(0, 0, 0, 0.45);
      &.ant-btn-loading::before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

}

.ant-input-search-button {
  height: 32px;
  &:hover, &:focus {
    z-index: 1;
  }
}

.ant-input-search-large .ant-input-search-button {
  height: 40px;
}

.ant-input-search-small .ant-input-search-button {
  height: 24px;
}

.ant-input-search-rtl {

  .ant-input {
    &:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
    &:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
      border-right-color: #40a9ff;
      border-left-color: #d9d9d9;
    }
  }

  > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
    border-radius: 2px 0 0 2px;
  }

  &.ant-input-search-enter-button {
    + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only {
      width: 32px;
      height: 32px;
    }
    input {
      + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only {
        width: 32px;
        height: 32px;
      }
      + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only.ant-btn-sm {
        width: 24px;
        height: 24px;
      }
      + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only.ant-btn-lg {
        width: 40px;
        height: 40px;
      }
    }
    + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only.ant-btn-sm {
      width: 24px;
      height: 24px;
    }
    + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only.ant-btn-lg {
      width: 40px;
      height: 40px;
    }
  }
}

.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}

//! original code
// .ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
//   border-radius: 0;
// }

// .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
// .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
//   border-left-color: #40a9ff;
// }

// .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
//   padding-top: 0;
//   padding-bottom: 0;
//   border-radius: 0 2px 2px 0;
// }
// .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
//   color: rgba(0, 0, 0, 0.45);
// }
// .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
// }
// .ant-input-search-button {
//   height: 32px;
// }
// .ant-input-search-button:hover,
// .ant-input-search-button:focus {
//   z-index: 1;
// }
// .ant-input-search-large .ant-input-search-button {
//   height: 40px;
// }
// .ant-input-search-small .ant-input-search-button {
//   height: 24px;
// }

// .ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
// .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
//   border-right-color: #40a9ff;
//   border-left-color: #d9d9d9;
// }
// .ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
//   border-radius: 2px 0 0 2px;
// }

// .ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only,
// .ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only {
//   width: 32px;
//   height: 32px;
// }
// .ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only.ant-btn-sm,
// .ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only.ant-btn-sm {
//   width: 24px;
//   height: 24px;
// }
// .ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only.ant-btn-lg,
// .ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only.ant-btn-lg {
//   width: 40px;
//   height: 40px;
// }
// .ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
//   border-radius: 0;
// }

//? 15. btn-rtl----------------------------------------------------------------
// 일부 btn-group-rtl
.ant-btn-rtl {
  direction: rtl;
  &.ant-btn > {
    .ant-btn-loading-icon .anticon {
      padding-right: 0;
      padding-left: 8px;
    }
    .anticon + span, span + .anticon {
      margin-right: 8px;
      margin-left: 0;
    }
  }
}

//? 16. btn-icon-only----------------------------------------------------------------
.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -3px;

  > * {
    font-size: 16px;
  }

  &.ant-btn-lg {
    width: 40px;
    height: 40px;
    padding: 4.9px 0;
    font-size: 18px;
    border-radius: 2px;
    > * {
      font-size: 18px;
    }
  }

  &.ant-btn-sm {
    width: 24px;
    height: 24px;
    padding: 0px 0;
    font-size: 14px;
    border-radius: 2px;
    > * {
      font-size: 14px;
    }
  }

  > .anticon {
    display: flex;
    justify-content: center;
  }
}

a.ant-btn-icon-only {
  vertical-align: -1px;
  > .anticon {
    display: inline;
  }
}

//! original code
// .ant-btn-icon-only {
//   width: 32px;
//   height: 32px;
//   padding: 2.4px 0;
//   font-size: 16px;
//   border-radius: 2px;
//   vertical-align: -3px;
// }
// .ant-btn-icon-only > * {
//   font-size: 16px;
// }
// .ant-btn-icon-only.ant-btn-lg {
//   width: 40px;
//   height: 40px;
//   padding: 4.9px 0;
//   font-size: 18px;
//   border-radius: 2px;
// }
// .ant-btn-icon-only.ant-btn-lg > * {
//   font-size: 18px;
// }
// .ant-btn-icon-only.ant-btn-sm {
//   width: 24px;
//   height: 24px;
//   padding: 0px 0;
//   font-size: 14px;
//   border-radius: 2px;
// }
// .ant-btn-icon-only.ant-btn-sm > * {
//   font-size: 14px;
// }
// .ant-btn-icon-only > .anticon {
//   display: flex;
//   justify-content: center;
// }
// a.ant-btn-icon-only {
//   vertical-align: -1px;
// }
// a.ant-btn-icon-only > .anticon {
//   display: inline;
// }

// 기타
.ant-dropdown-button .ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}




