//* company-modal-table

.table-guide {
  display: block;
  text-align: end;
  padding-right: 26px;
  font-size: 10px;
  font-style: italic;
  color: #8eb3ba;
}

.table {
  margin: 0 auto 10px auto;
}

.tbody {
  border: 1px solid #c5dade;
  margin: 5px 0 10px 0;
}

.tr {
  font-size: 11px;
  
  > .th {
    background-color: #f5f9fa;
    color: #8eb3ba;
    border-right: 1px solid #c5dade;
    padding: 15px 5px;
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid #c5dade;
    &:last-of-type  {
      border-left: 1px solid #c5dade;
      vertical-align: middle;
      border-bottom: 1px solid #c5dade;
    }
  }
  > .td {
    padding: 10px;
    border-bottom: 1px solid #ecf5f7;
  }
  &:last-of-type {
    > .td {
      border-bottom: 1px solid #c5dade;
    }
  }

}

.table-btns {
  display: block;
  font-size: 11px;
  > .table-btn-reset {
    margin-left: 26px;
    margin-right: 358px;
  }
  > .table-btn-save {
    text-align: end;
    margin-left: 10px;
  }
}

//* user-modal-table

.table-user {
  margin: 0 auto 10px auto;
  width: 92%;
  > .tbody-user {
    border: 1px solid #c5dade;
    margin: 5px 0 10px 0;
    > .tr {
      font-size: 11px;
      
      > .th {
        background-color: #f5f9fa;
        color: #8eb3ba;
        border-right: 1px solid #c5dade;
        padding: 15px 5px;
        text-align: center;
        vertical-align: middle;
        border-bottom: 1px solid #c5dade;
        width: 15%;
        text-rendering: auto;
        &:last-of-type  {
          border-left: 1px solid #c5dade;
          vertical-align: middle;
          border-bottom: 1px solid #c5dade;
        }
      }
      > .td {
        padding: 10px;
        border-bottom: 1px solid #ecf5f7;
        width: 35%;
      }
      &:last-of-type {
        > .td {
          border-bottom: 1px solid #c5dade;
        }
      }
    
    }
  }
}

//* parent-company-modal-table

.table-guide-parent-company {
  display: block;
  text-align: end;
  padding-right: 26px;
  font-size: 10px;
  font-style: italic;
  color: #8eb3ba;
  margin-top: 20px;
}

.table-parent-company {
  margin: 0 auto 10px auto;
  width: 92%;
  > .tbody-parent-company {
    border: 1px solid #c5dade;
    margin: 5px 0 10px 0;
    > .tr {
      font-size: 11px;
      
      > .th {
        background-color: #f5f9fa;
        color: #8eb3ba;
        border-right: 1px solid #c5dade;
        padding: 15px 5px;
        text-align: center;
        vertical-align: middle;
        border-bottom: 1px solid #c5dade;
        width: 15%;
        text-rendering: auto;
        &:last-of-type  {
          border-left: 1px solid #c5dade;
          vertical-align: middle;
          border-bottom: 1px solid #c5dade;
        }
      }
      > .td {
        padding: 10px;
        border-bottom: 1px solid #ecf5f7;
        width: 35%;
      }
      &:last-of-type {
        > .td {
          border-bottom: 1px solid #c5dade;
        }
      }
    
    }
  }
}

.table-btns-parent-company {
  display: block;
  font-size: 11px;
  padding-left: 26px;
  margin-top: 35px;
  margin-bottom: 20px;
  > .table-btn-save {
    margin-left: 10px;
  }
}





