.drag-uploader-tempo {
  width: 850px;
  height: 1200px;
}

.pdf-viewer {
  height: 100vh;
  max-width: 885px;
  > div {
    *::-moz-selection {
      background: rgba(#ff0000, 0.9);
      color: #000;
    }
    *::selection {
      background: rgba(#ff0000, 0.9);
      color: #000;
    }
  }
  > .ng2-pdf-viewer-container {
    min-width: 590px;
    max-width: 100%;
  }

}