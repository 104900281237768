#header {
  .header-logo {
    img {
      width: 165px;
      margin-left: 8.5px;
    }
  }
  > button {
    border-radius: 20px;
    margin: 6px 10px 6px auto;
    font-size: 11px;
    border: 1px solid #fff;
  } 
  
}
