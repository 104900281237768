// btns

@include smDesktop {
  .bl-reg-btns {
    @include flex-row;
    min-width: 1200px;
    max-width: 1200px;
    margin: 20px auto;
    justify-content: flex-end;
    > button {
      background-color: #0b6277;
      color: #fff;
      outline: none;
      justify-content: flex-end;
      margin-left: 10px;
    }
  }
}

@include lgDesktop {
  .bl-reg-btns {
    @include flex-row;
    min-width: 1600px;
    max-width: 1600px;
    margin: 20px auto;
    justify-content: flex-end;
    > button {
      background-color: #0b6277;
      color: #fff;
      outline: none;
      justify-items: flex-end;
      margin-left: 10px;
    }
  }
}

// condition

@include smDesktop {
  .bl-reg-condition {
    border: 1px solid #c5dade;
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;

    .first-row {
      @include flex-row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      .search-th {
        background: #f5f9fa;
        color: #8eb3ba;
        padding: 20px 0;
        width: 15%;
        text-align: center;
        border-right: 1px solid #c5dade;
        border-left: 1px solid #c5dade;
        &:nth-child(1) {
          border-left: none;
        }
      }
      .search-td {
        background: #fff;
        text-align: center;
        margin: 0 auto;
      }

    }

  }
}

@include lgDesktop {
  .bl-reg-condition {
    border: 1px solid #c5dade;
    max-width: 1600px;
    min-width: 1600px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;

    .first-row {
      @include flex-row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      border-bottom: 1px solid #c5dade;

      .search-th {
        background: #f5f9fa;
        color: #8eb3ba;
        padding: 20px 0;
        width: 15%;
        text-align: center;
        border-right: 1px solid #c5dade;
        border-left: 1px solid #c5dade;
        &:nth-child(1) {
          border-left: none;
        }
      }
      .search-td {
        background: #fff;
        text-align: center;
        margin: 0 auto;
      }

    }

    .second-row {
      @include flex-row;
      justify-self: flex-start;
      align-items: center;
      flex-wrap: nowrap;

      .search-th {
        background: #f5f9fa;
        color: #8eb3ba;
        padding: 20px 0;
        width: 15%;
        text-align: center;
        border-right: 1px solid #c5dade;
        border-left: 1px solid #c5dade;
        &:nth-child(1) {
          border-left: none;
        }
      }
      .search-td {
        background: #fff;
        text-align: center;
        margin: 0 auto;
        width: 180px;
        // width: 15%;

      }
      .empty {
        padding: 20px 0;
        text-align: center;
        border-left: 1px solid #c5dade;
        width: 1059px;
      }

    }

  }
}

// // result

// @include smDesktop {
//   .bl-reg-result {
//     margin: 30px auto;
//     background-color: #fff;
//     max-width: 1200px;
//     min-width: 1200px;
//     align-self: center;
//     // 임시
//     height: 700px;
//   }
// }

// @include lgDesktop {
//   .bl-reg-result {
//     margin: 30px auto;
//     background-color: #fff;
//     max-width: 1600px;
//     min-width: 1600px;
//     align-self: center;
//     // 임시
//     height: 700px;
//   }
// }

// // update

// @include smDesktop {
//   .bl-reg-update {
//     height: 500px;
//     display: flex;
//     flex-direction: column;
//     overflow-y: auto;
//   }
  // .bl-reg-contents {
  //   background-color: #fff;
  //   height: 90%;
  //   .bl-reg-card {
  //     .bl-reg-desc {
  //       // color: #8eb3ba; 가장진함
  //       // color: #c5dade;
  //       // color: #f5f9fa; 가장연함
  //       .bl-reg-category {
  //         margin-bottom: 30px;
  //         margin-right: 10px;
  //         border: 1px solid #c5dade;
  //         .bl-reg-title {
  //           padding: 10px 15px;
  //           background-color: #f5f9fa;
  //           color: #0b6277;
  //           font-size: 15px;
  //           font-weight: 600;
  //         }
  //         .bl-reg-useYn {
  //           color: #8eb3ba;
  //           padding: 5px 15px;
  //           font-size: 13px;
  //           span {
  //             margin-right: 10px;
  //           }
  //         }
  //         .bl-reg-useYn-label {
  //           font-size: 13px;
  //           color: #8eb3ba;
  //           font-weight: 400;
  //         }
  //         .bl-reg-select {
  //           padding: 0 15px;
  //           margin-bottom: 15px;

  //         }
          // .bl-reg-cInfo {
          //   display: flex;
          //   flex-direction: row;
          //   padding: 2px 0px; //2023.01.17 수정
          //   div { //2023.01.17 추가
          //     width:250px;
          //     margin-left:10px;
          //   }
          //   span {
          //     color: #000; //2023.01.17 수정
          //     font-size: 12px;
          //     font-weight: 400;
          //     line-height: 2.66em;
          //   }
          //   &:last-child {
          //     padding-bottom: 15px;
          //   }
          // }
  //       }
  //     }
  //   }
  // }
//   .bl-reg-update-btns {
//     height: 10%;
//     margin-top: 10px;
//     text-align: end;
//     .bl-reg-btn-cancel {
//       margin-right: 10px;
//     }
//   }

// }


// @include lgDesktop {

// }

// // antd 관련 => 전체 적용


.ant-radio-checked .ant-radio-inner {
  border-color:#0b6277;
}
.ant-radio-inner {
  &::after {
    border:1px solid #0b6277; // 2023.01.17 border수정
    background-color: #0b6277;
  }
}
.test-label {
  color: #0b6277;
}



// 2023.01.11 css 시작
// 2023.01.17 레이아웃 수정 시작
// media 쿼리 변경

.ant-modal-confirm .ant-modal-body {
  padding: 65px 32px 24px;
  height: 200px;
} // 2023.01.26 알럿 UI 수정


  .ant-modal-body {
    width:100%;
    height:700px;
    padding:25px;
    background:#fff;

  }
  .bl-reg-update {
    width:100%;
    height:555px;
    overflow-y:auto;
    overflow-x:hidden;
  }

  .bl-reg-desc {
    border:1px solid #c5dade;
    margin-bottom:20px;
  }

  .bl-reg-title {
    height:50px;
    line-height:50px;
    font-weight:700;
    color:$color-point1;
    padding-left:25px;
    background:#f5f9fa;
  }

  .bl-reg-useYn {
    color:#8eb3ba;
    padding:20px;
  }
  .bl-reg-useYn > span {
    font-weight:700;
  }

  .bl-reg-select {
    width:100%;
    padding:0 0 20px 20px;
  }

  .bl-reg-cInfo {
    display: flex;
    flex-direction: row;
    padding: 2px 0px;
    div {
      width:250px;
      margin-left:10px;
    }
    span {
      @include font-xs;
      color: $color-black;
      line-height: 2.66em;
    }
    &:last-child {
      padding-bottom: 15px;
    }
  }

  .bl-reg-cInfo > span {
    font-weight:700;
    color:$color-point1;
   }

  .bl-reg-cInfo:last-child > span {
    width:90px;
  }

  .bl-reg-update-btns {
    position:relative;
    left:50%;
    width:200px; // 2023.01.26 수정
    margin-top:40px;
    margin-left:170px; // 2023.01.26 수정
  }

  .bl-reg-btn-cancel {
    margin-right:10px;
  }


// media 쿼리 변경
@media all and ( max-width:744px ){

  .bl-reg-cInfo {
    flex-direction: column;
  }

  .bl-reg-update-btns {
    left:0;
    margin-left:0;
  }

}

