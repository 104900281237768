//! webkit scroll bar
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}
::-webkit-scrollbar-thumb {
  background: $color-point2;
  border: 0 none transparent;
  &:hover {
    background: $color-point2;
  }
  &:active {
    background: $color-point2;
  }
}
::-webkit-scrollbar-track {
  background: $color-sub2;
  border: 0 none transparent;
  &:hover {
    background: $color-sub2;
  }
  &:active {
    background: $color-sub2;
  }
}
::-webkit-scrollbar-corner {
  background: transparent;
}
