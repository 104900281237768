

#login-wrap {
  @include flex-column;
  height: 100vh;
  background-color: #72b6c6;
  justify-content: center;
  align-items: center;
}

#login-container {
  width: 480px;
  height: 480px;
  padding: 30px 75px;
  border-radius: 28px;
  box-shadow: 10px 10px 15px 0 rgba(43, 86, 96, 0.27);
  background-color: #fff;
}

.login-logo {
  width: 70%;
  margin: 40px 0 30px 0;
} // 2023.01.26 수정

.login-admin {
  width: 200px; // 2023.01.26 수정
  height: 29px;
  margin: 15px 133px 20px 0; // 2023.01.26 수정
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #086277;
}

.input-form {
  margin: 0 auto;
  font-family: NotoSansKR;
  > input {
    width: 330px;
    height: 45px; // 2023.01.26 수정
    margin: 10px 0 15px;
    background-color: #fff;
    outline: none;
    border: none;
    border-bottom: solid 1px #ededed;
    padding-left: 15px; // 2023.01.26 추가
    &::placeholder {
      margin-bottom: 10px;
      font-size: 14px; // 2023.01.26 수정
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: left;
      color: #929292; // 2023.01.26 수정
    }
    &.valid-err {
      color: green;
    }
    .valid-err {
      color: blue;
    }
  }
  > p {
    height: 11px;
    float: right;
    font-size: 8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #f76464;
  }
  > .valid-err {
    height: 11px;
    float: right;
    font-size: 8px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #f76464;
  }
  > button {
    width: 330px;
    height: 45px; // 2023.01.26 수정
    margin: 20px 45px 0 0; // 2023.01.26 수정
    padding: 12px 140px 11px 140px;
    border-radius: 20px;
    background-color: #0b6277;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
  }
}