@charset "UTF-8";

#wrap {
  @include flex-column;
  height: 100vh;
  display: flex;
  position: relative;
  flex: 1 1 auto;
}

#header {
  @include flex-align-center;
  @include box-shadow(0px, 5px, 5px, rgba($color-black, .08));
  position: relative;
  background: $color-white;
  z-index: 1;
  height: 44px;
  line-height: 5px;
  padding: 0 5px;
}

#container {
  display: flex;
  height: 100vh;
}

#contents {
  flex: 1 1 auto;
  background: $color-sub1;
  margin-left: 200px;
  padding: 50px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
}



//! ------------- test

.test-class {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 5px solid red;
  z-index: 999;
}