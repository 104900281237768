.user-comp-search-btn {
  float: right;
  margin-bottom: 15px;
  margin-top: 0;
}

@include smDesktop {
  .user-comp-search-condition {
    max-width: 812px;
    min-width: 812px;
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    flex-wrap: nowrap;
    border: 1px solid #c5dade;
    margin: 0 auto 15px auto;
    .search-th {
      background: #f5f9fa;
      color: #8eb3ba;
      padding: 20px 0;
      width: 13%;
      text-align: center;
      border-right: 1px solid #c5dade;
      border-left: 1px solid #c5dade;
      &:nth-child(1) {
        border-left: none;
      }
    }
    .search-td {
      background: #fff;
      text-align: center;
      margin: 0 auto;
      width: 150px;
    }

  }
}

// modal 사이즈 조절 후 수정 필요함
@include lgDesktop {
  .user-comp-search-condition {
    // max-width: 1600px;
    // min-width: 1600px;
    max-width: 812px;
    min-width: 812px;
    margin: 0 20px;
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    flex-wrap: nowrap;
    border: 1px solid #c5dade;
    margin: 0 auto 15px auto;
    .search-th {
      background: #f5f9fa;
      color: #8eb3ba;
      padding: 20px 0;
      width: 15%;
      text-align: center;
      border-right: 1px solid #c5dade;
      border-left: 1px solid #c5dade;
      &:nth-child(1) {
        border-left: none;
      }
    }
    .search-td {
      background: #fff;
      text-align: center;
      margin: 0 auto;
      width: 150px;
    }
  }
}

@include smDesktop {
  .user-comp-search-result {
    margin: 0 auto 20px auto;
    height: 340px;
    max-width: 812px;
    min-width: 812px;
  
  }
}

@include lgDesktop {
  .user-comp-search-result {
    margin: 0 auto 20px auto;
    height: 340px;
    max-width: 812px;
    min-width: 812px;

  }
}


// .ant-table-expanded-row-fixed .ng-star-inserted {
//   height: 90px;
//   position: sticky;
//     left: 0px;
//     overflow: hidden;
//     width: auto;
//     margin: 0 auto;
//     text-align: center;

// }

