.btn-control {
  position: fixed;
  top: 7.8px;
  left: 186px;
  width: 30px;
  height: 30px;
  background: $color-point1;
  border-radius: 50%;
  text-indent: -9999em;
  z-index: 1000;
  &:after,
  &:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 8px;
    background: $color-white;
    transition-duration: 550ms;
  }
  &:before {
    right: 14px;
    bottom: 50%;
    margin-bottom: -2px;
    transform: rotate(-135deg);
  }
  &:after {
    right: 14px;
    top: 50%;
    margin-top: -2px;
    transform: rotate(-45deg);
  }
  &.active {
    &:before {
      right: 12px;
      bottom: 50%;
      margin-bottom: -2px;
      transform: rotate(-45deg);
    }
    &:after {
      right: 12px;
      top: 50%;
      margin-top: -2px;
      transform: rotate(-135deg);
    }
  }
}

#lnb {
  @include flex-column;
  position: absolute;
  top: 0;
  bottom: 0;  
  padding-top: 44px;

  .lnb-menu {
    flex: 1 1 auto;
    padding: 50px 10px;
    overflow-y: auto;
    left: -200px;
    width: 200px;
    position: absolute;
    background-color: $color-point2;
    @include box-shadow(5px, 0px, 5px, rgba($color-black, .08));
    height: calc(100vh - 44px);

			.menu-depth2 {
				position: relative;
				z-index: 0;
				display: none;
				border-radius: $md-radius;
				margin-top: -15px;
				padding-top: 20px;
				background: $color-white;
				@include box-shadow(0, 0, 6px, rgba($color-black, .18));
        @include flex-column;
				@include font-md-b;
				color: $color-point1;
				height: 278px;

				.menu-depth2-link {
					@include flex-all-center;
					color: $color-point1;
					height: 40px;
					&:hover {						
            font-weight: 700;
            font-size: 15px;
					}
          &.active {
            font-weight: 700;
            font-size: 15px;
            color: $color-point1;
            text-shadow: 0.2px 0.4px 0.4px rgba($color-black, .18);
          }
				}

			}

    }
    
  // }

  &.minify {
    left: -200px;
    + #contents {
      margin-left: 0px;
    }
  }
	
}



