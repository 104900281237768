@charset "UTF-8";
@-webkit-keyframes wobble-hor-top {
  0%, 100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateX(-12px) rotate(4deg);
  }
  30% {
    transform: translateX(7px) rotate(-4deg);
  }
  45% {
    transform: translateX(-7px) rotate(2.6deg);
  }
  60% {
    transform: translateX(3px) rotate(-1.4deg);
  }
  75% {
    transform: translateX(-3px) rotate(0.2deg);
  }
}
@keyframes wobble-hor-top {
  0%, 100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateX(-12px) rotate(4deg);
  }
  30% {
    transform: translateX(7px) rotate(-4deg);
  }
  45% {
    transform: translateX(-7px) rotate(2.6deg);
  }
  60% {
    transform: translateX(3px) rotate(-1.4deg);
  }
  75% {
    transform: translateX(-3px) rotate(0.2deg);
  }
}
@-webkit-keyframes bounce-top {
  0% {
    transform: translateY(-16px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(-2px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%, 55%, 75%, 87% {
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    transform: translateY(-16px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(-2px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%, 55%, 75%, 87% {
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@-webkit-keyframes icon-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360);
  }
}
@keyframes icon-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes dummy-animation {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
@keyframes dummy-animation {
  0% {
    background-position: -650px 0;
  }
  100% {
    background-position: 650px 0;
  }
}
@-webkit-keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@-webkit-keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@-webkit-keyframes zoom-in {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0px);
  }
}
@keyframes zoom-in {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0px);
  }
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #72b6c6;
  border: 0 none transparent;
}
::-webkit-scrollbar-thumb:hover {
  background: #72b6c6;
}
::-webkit-scrollbar-thumb:active {
  background: #72b6c6;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
  border: 0 none transparent;
}
::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}
::-webkit-scrollbar-track:active {
  background: #e0e0e0;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

#wrap {
  display: flex;
  flex-direction: column;
  height: 100vh;
  display: flex;
  position: relative;
  flex: 1 1 auto;
}

#header {
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
  z-index: 1;
  height: 44px;
  line-height: 5px;
  padding: 0 5px;
}

#container {
  display: flex;
  height: 100vh;
}

#contents {
  flex: 1 1 auto;
  background: #f5f5f5;
  margin-left: 200px;
  padding: 50px;
  max-height: calc(100vh - 44px);
  overflow-y: auto;
}

.test-class {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 5px solid red;
  z-index: 999;
}

#header .header-logo img {
  width: 165px;
  margin-left: 8.5px;
}
#header > button {
  border-radius: 20px;
  margin: 6px 10px 6px auto;
  font-size: 11px;
  border: 1px solid #fff;
}

.btn-control {
  position: fixed;
  top: 7.8px;
  left: 186px;
  width: 30px;
  height: 30px;
  background: #0b6277;
  border-radius: 50%;
  text-indent: -9999em;
  z-index: 1000;
}
.btn-control:after, .btn-control:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 8px;
  background: #fff;
  transition-duration: 550ms;
}
.btn-control:before {
  right: 14px;
  bottom: 50%;
  margin-bottom: -2px;
  transform: rotate(-135deg);
}
.btn-control:after {
  right: 14px;
  top: 50%;
  margin-top: -2px;
  transform: rotate(-45deg);
}
.btn-control.active:before {
  right: 12px;
  bottom: 50%;
  margin-bottom: -2px;
  transform: rotate(-45deg);
}
.btn-control.active:after {
  right: 12px;
  top: 50%;
  margin-top: -2px;
  transform: rotate(-135deg);
}

#lnb {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  padding-top: 44px;
}
#lnb .lnb-menu {
  flex: 1 1 auto;
  padding: 50px 10px;
  overflow-y: auto;
  left: -200px;
  width: 200px;
  position: absolute;
  background-color: #72b6c6;
  box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.08);
  height: calc(100vh - 44px);
}
#lnb .lnb-menu .menu-depth2 {
  position: relative;
  z-index: 0;
  display: none;
  border-radius: 10px;
  margin-top: -15px;
  padding-top: 20px;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  color: #0b6277;
  height: 278px;
}
#lnb .lnb-menu .menu-depth2 .menu-depth2-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0b6277;
  height: 40px;
}
#lnb .lnb-menu .menu-depth2 .menu-depth2-link:hover {
  font-weight: 700;
  font-size: 15px;
}
#lnb .lnb-menu .menu-depth2 .menu-depth2-link.active {
  font-weight: 700;
  font-size: 15px;
  color: #0b6277;
  text-shadow: 0.2px 0.4px 0.4px rgba(0, 0, 0, 0.18);
}
#lnb.minify {
  left: -200px;
}
#lnb.minify + #contents {
  margin-left: 0px;
}

#login-wrap {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #72b6c6;
  justify-content: center;
  align-items: center;
}

#login-container {
  width: 480px;
  height: 480px;
  padding: 30px 75px;
  border-radius: 28px;
  box-shadow: 10px 10px 15px 0 rgba(43, 86, 96, 0.27);
  background-color: #fff;
}

.login-logo {
  width: 70%;
  margin: 40px 0 30px 0;
}

.login-admin {
  width: 200px;
  height: 29px;
  margin: 15px 133px 20px 0;
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #086277;
}

.input-form {
  margin: 0 auto;
  font-family: NotoSansKR;
}
.input-form > input {
  width: 330px;
  height: 45px;
  margin: 10px 0 15px;
  background-color: #fff;
  outline: none;
  border: none;
  border-bottom: solid 1px #ededed;
  padding-left: 15px;
}
.input-form > input::placeholder {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #929292;
}
.input-form > input.valid-err {
  color: green;
}
.input-form > input .valid-err {
  color: blue;
}
.input-form > p {
  height: 11px;
  float: right;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #f76464;
}
.input-form > .valid-err {
  height: 11px;
  float: right;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #f76464;
}
.input-form > button {
  width: 330px;
  height: 45px;
  margin: 20px 45px 0 0;
  padding: 12px 140px 11px 140px;
  border-radius: 20px;
  background-color: #0b6277;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
}

.ant-btn-group {
  position: relative;
  display: inline-flex;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #40a9ff;
  border-left-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child):disabled {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #40a9ff;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover, .ant-btn-group > .ant-btn:focus, .ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}
.ant-btn-group > .ant-btn:disabled,
.ant-btn-group > span > .ant-btn:disabled {
  z-index: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child,
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group .ant-btn-group-rtl {
  direction: rtl;
}

.ant-dropdown-button.ant-btn-group > .ant-btn-loading {
  cursor: default;
  pointer-events: none;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn {
  cursor: default;
  pointer-events: none;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn::before {
  display: block;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-btn + .ant-btn-group {
  margin-left: -1px;
}

.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 0;
}
.ant-btn-group-lg .ant-btn .ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}

.ant-btn-group-sm .ant-btn .ant-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 0;
}
.ant-btn-group-sm > .ant-btn > .action,
.ant-btn-group-sm > span > .ant-btn > .action {
  font-size: 14px;
}
.ant-btn-group-sm > .ant-btn:only-child,
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}

.ant-btn-grou-rtl > .ant-btn:first-child:not(:last-child),
.ant-btn-grou-rtl > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}
.ant-btn-grou-rtl > .ant-btn:last-child:not(:first-child),
.ant-btn-grou-rtl > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}
.ant-btn-grou-rtl .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-grou-rtl .ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #40a9ff;
  border-left-color: #d9d9d9;
}
.ant-btn-grou-rtl .ant-btn-group .ant-btn-primary:last-child:not(:first-child):disabled,
.ant-btn-grou-rtl .ant-btn-group .ant-btn-primary + .ant-btn-primary:disabled {
  border-right-color: #d9d9d9;
  border-left-color: #40a9ff;
}
.ant-btn-grou-rtl .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-grou-rtl .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}
.ant-btn-grou-rtl .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-grou-rtl .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}

.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  outline: 0;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn > a:only-child {
  color: currentcolor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn > span {
  display: inline-block;
}
.ant-btn :not([disabled]):hover {
  text-decoration: none;
}
.ant-btn :not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn:disabled {
  cursor: not-allowed;
}
.ant-btn:disabled > * {
  pointer-events: none;
}
.ant-btn:focus {
  outline: 0;
  text-decoration: none;
  background: #fff;
}
.ant-btn:hover, .ant-btn:focus {
  color: #0b6277;
  border-color: #40a9ff;
  background: #fff;
  text-decoration: none;
}
.ant-btn:hover > a:only-child, .ant-btn:focus > a:only-child {
  color: currentcolor;
}
.ant-btn:hover > a:only-child::after, .ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn:active {
  outline: 0;
  color: #096dd9;
  border-color: #096dd9;
  background: #fff;
}
.ant-btn:active > a:only-child {
  color: currentcolor;
}
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn:disabled:hover, .ant-btn:disabled:focus, .ant-btn:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn:disabled > a:only-child:hover, .ant-btn:disabled > a:only-child:focus, .ant-btn:disabled > a:only-child:active {
  color: currentcolor;
}
.ant-btn:disabled > a:only-child:hover::after, .ant-btn:disabled > a:only-child:focus::after, .ant-btn:disabled > a:only-child:active::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: "";
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon .anticon-plus > svg,
.ant-btn .anticon .anticon-minus > svg {
  shape-rendering: optimizespeed;
}
.ant-btn .ant-btn-loading {
  position: relative;
  cursor: default;
}
.ant-btn .ant-btn-loading::before {
  display: block;
}
.ant-btn > .ant-btn-loading-icon {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  animation: none;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
  animation: loadingCircle 1s infinite linear;
}
.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
  padding-left: 0;
}
.ant-btn:focus > span, .ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn .ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}
.ant-btn .ant-btn-background-ghost:hover, .ant-btn .ant-btn-background-ghost:focus {
  background: transparent;
  color: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn .ant-btn-background-ghost:active {
  background: transparent;
  color: #096dd9;
  border-color: #096dd9;
}
.ant-btn .ant-btn-background-ghost:disabled {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: #d9d9d9;
}
.ant-btn .ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: " ";
}

a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 30px;
}

.ant-btn-primary {
  color: #fff;
  border-color: #0b6277;
  background: #0b6277;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  border-color: #0b6277;
  background: #0b6277;
}
.ant-btn-primary:hover > a:only-child, .ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:hover > a:only-child::after, .ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-primary:active {
  color: #fff;
  border-color: #096dd9;
  background: #096dd9;
}
.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-primary:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary:disabled:hover, .ant-btn-primary:disabled:focus, .ant-btn-primary:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary:disabled:hover > a:only-child, .ant-btn-primary:disabled:focus > a:only-child, .ant-btn-primary:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:disabled:hover > a:only-child::after, .ant-btn-primary:disabled:focus > a:only-child::after, .ant-btn-primary:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}

.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dashed:hover, .ant-btn-dashed:focus {
  color: #0b6277;
  border-color: #0b6277;
  background: #fff;
}
.ant-btn-dashed:hover > a:only-child, .ant-btn-dashed:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:hover > a:only-child::after, .ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dashed:active {
  color: #096dd9;
  border-color: #096dd9;
  background: #fff;
}
.ant-btn-dashed:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dashed:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dashed:disabled:hover, .ant-btn-dashed:disabled:focus, .ant-btn-dashed:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed:disabled:hover > a:only-child, .ant-btn-dashed:disabled:focus > a:only-child, .ant-btn-dashed:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:disabled:hover > a:only-child::after, .ant-btn-dashed:disabled:focus > a:only-child::after, .ant-btn-dashed:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}

.ant-btn-link {
  color: #0b6277;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-link:hover, .ant-btn-link:focus {
  color: #40a9ff;
  border-color: #0b6277;
  background: transparent;
}
.ant-btn-link:hover > a:only-child, .ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-link:hover > a:only-child::after, .ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-link:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-link:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-link:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-link:disabled:hover, .ant-btn-link:disabled:focus, .ant-btn-link:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link:disabled:hover > a:only-child, .ant-btn-link:disabled:focus > a:only-child, .ant-btn-link:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link:disabled:hover > a:only-child::after, .ant-btn-link:disabled:focus > a:only-child::after, .ant-btn-link:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-link:hover {
  background: transparent;
  border-color: transparent;
}
.ant-btn-link:focus, .ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-link:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-link:disabled:hover, .ant-btn-link:disabled:focus, .ant-btn-link:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link:disabled:hover > a:only-child, .ant-btn-link:disabled:focus > a:only-child, .ant-btn-link:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link:disabled:hover > a:only-child::after, .ant-btn-link:disabled:focus > a:only-child::after, .ant-btn-link:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}

.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-text:hover, .ant-btn-text:focus {
  color: #0b6277;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-text:hover > a:only-child, .ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-text:hover > a:only-child::after, .ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-text:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-text:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-text:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-text:disabled:hover, .ant-btn-text:disabled:focus, .ant-btn-text:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text:disabled:hover > a:only-child, .ant-btn-text:disabled:focus > a:only-child, .ant-btn-text:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text:disabled:hover > a:only-child::after, .ant-btn-text:disabled:focus > a:only-child::after, .ant-btn-text:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-text:hover, .ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-text:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-text:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-text:disabled:hover, .ant-btn-text:disabled:focus, .ant-btn-text:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text:disabled:hover > a:only-child, .ant-btn-text:disabled:focus > a:only-child, .ant-btn-text:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text:disabled:hover > a:only-child::after, .ant-btn-text:disabled:focus > a:only-child::after, .ant-btn-text:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}

.ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle .ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle .ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}

.ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}
.ant-btn-round .ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round .ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round .ant-btn-icon-only {
  width: auto;
}

.ant-btn-lg {
  height: 30px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 2px;
}

a.ant-btn-lg {
  line-height: 32px;
}

.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}

a.ant-btn-sm {
  line-height: 22px;
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 20px;
  line-height: 1;
}

.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  background: #fff;
}
.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous:hover, .ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
  background: #fff;
}
.ant-btn-dangerous:hover > a:only-child, .ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:hover > a:only-child::after, .ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
  background: #fff;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous:disabled:hover, .ant-btn-dangerous:disabled:focus, .ant-btn-dangerous:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous:disabled:hover > a:only-child, .ant-btn-dangerous:disabled:focus > a:only-child, .ant-btn-dangerous:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:disabled:hover > a:only-child::after, .ant-btn-dangerous:disabled:focus > a:only-child::after, .ant-btn-dangerous:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-primary:hover, .ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child, .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after, .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous:disabled:hover, .ant-btn-dangerous:disabled:focus, .ant-btn-dangerous:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous:disabled:hover > a:only-child, .ant-btn-dangerous:disabled:focus > a:only-child, .ant-btn-dangerous:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:disabled:hover > a:only-child::after, .ant-btn-dangerous:disabled:focus > a:only-child::after, .ant-btn-dangerous:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-link:hover, .ant-btn-dangerous.ant-btn-link:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child, .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after, .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-link:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-link:disabled:hover, .ant-btn-dangerous.ant-btn-link:disabled:focus, .ant-btn-dangerous.ant-btn-link:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link:disabled:hover > a:only-child, .ant-btn-dangerous.ant-btn-link:disabled:focus > a:only-child, .ant-btn-dangerous.ant-btn-link:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:disabled:hover > a:only-child::after, .ant-btn-dangerous.ant-btn-link:disabled:focus > a:only-child::after, .ant-btn-dangerous.ant-btn-link:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-link:hover, .ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child, .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after, .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-link:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-link:disabled:hover, .ant-btn-dangerous.ant-btn-link:disabled:focus, .ant-btn-dangerous.ant-btn-link:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link:disabled:hover > a:only-child, .ant-btn-dangerous.ant-btn-link:disabled:focus > a:only-child, .ant-btn-dangerous.ant-btn-link:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:disabled:hover > a:only-child::after, .ant-btn-dangerous.ant-btn-link:disabled:focus > a:only-child::after, .ant-btn-dangerous.ant-btn-link:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-text:hover, .ant-btn-dangerous.ant-btn-text:focus {
  color: #40a9ff;
  border-color: #40a9ff;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child, .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after, .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #096dd9;
  border-color: #096dd9;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-text:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-text:disabled:hover, .ant-btn-dangerous.ant-btn-text:disabled:focus, .ant-btn-dangerous.ant-btn-text:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text:disabled:hover > a:only-child, .ant-btn-dangerous.ant-btn-text:disabled:focus > a:only-child, .ant-btn-dangerous.ant-btn-text:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:disabled:hover > a:only-child::after, .ant-btn-dangerous.ant-btn-text:disabled:focus > a:only-child::after, .ant-btn-dangerous.ant-btn-text:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-text:hover, .ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.018);
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child, .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after, .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.028);
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-text:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-dangerous.ant-btn-text:disabled:hover, .ant-btn-dangerous.ant-btn-text:disabled:focus, .ant-btn-dangerous.ant-btn-text:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text:disabled:hover > a:only-child, .ant-btn-dangerous.ant-btn-text:disabled:focus > a:only-child, .ant-btn-dangerous.ant-btn-text:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:disabled:hover > a:only-child::after, .ant-btn-dangerous.ant-btn-text:disabled:focus > a:only-child::after, .ant-btn-dangerous.ant-btn-text:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}

.ant-btn-background-ghost.ant-btn-primary {
  color: #fff;
  border-color: #1890ff;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-primary:hover, .ant-btn-background-ghost.ant-btn-primary:focus {
  color: #40a9ff;
  border-color: #40a9ff;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child, .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after, .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #096dd9;
  border-color: #096dd9;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-primary:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-primary:disabled:hover, .ant-btn-background-ghost.ant-btn-primary:disabled:focus, .ant-btn-background-ghost.ant-btn-primary:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary:disabled:hover > a:only-child, .ant-btn-background-ghost.ant-btn-primary:disabled:focus > a:only-child, .ant-btn-background-ghost.ant-btn-primary:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:disabled:hover > a:only-child::after, .ant-btn-background-ghost.ant-btn-primary:disabled:focus > a:only-child::after, .ant-btn-background-ghost.ant-btn-primary:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger:hover, .ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child, .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after, .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-danger:disabled:hover, .ant-btn-background-ghost.ant-btn-danger:disabled:focus, .ant-btn-background-ghost.ant-btn-danger:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger:disabled:hover > a:only-child, .ant-btn-background-ghost.ant-btn-danger:disabled:focus > a:only-child, .ant-btn-background-ghost.ant-btn-danger:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:disabled:hover > a:only-child::after, .ant-btn-background-ghost.ant-btn-danger:disabled:focus > a:only-child::after, .ant-btn-background-ghost.ant-btn-danger:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous:hover, .ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after, .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous:disabled:hover, .ant-btn-background-ghost.ant-btn-dangerous:disabled:focus, .ant-btn-background-ghost.ant-btn-dangerous:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous:disabled:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous:disabled:focus > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:disabled:hover > a:only-child::after, .ant-btn-background-ghost.ant-btn-dangerous:disabled:focus > a:only-child::after, .ant-btn-background-ghost.ant-btn-dangerous:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:hover, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:focus, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:focus > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:hover > a:only-child::after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:focus > a:only-child::after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:hover, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:focus, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:hover > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:focus > a:only-child, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled > a:only-child::after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:hover > a:only-child::after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:focus > a:only-child::after, .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: "";
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}

.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #40a9ff;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ant-input-search-button {
  height: 32px;
}
.ant-input-search-button:hover, .ant-input-search-button:focus {
  z-index: 1;
}

.ant-input-search-large .ant-input-search-button {
  height: 40px;
}

.ant-input-search-small .ant-input-search-button {
  height: 24px;
}

.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: #40a9ff;
  border-left-color: #d9d9d9;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 2px 0 0 2px;
}
.ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only {
  width: 32px;
  height: 32px;
}
.ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only {
  width: 32px;
  height: 32px;
}
.ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
}
.ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
}
.ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
}
.ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
}

.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}

.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.ant-btn-rtl.ant-btn > .anticon + span, .ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}

.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -3px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-icon-only > .anticon {
  display: flex;
  justify-content: center;
}

a.ant-btn-icon-only {
  vertical-align: -1px;
}
a.ant-btn-icon-only > .anticon {
  display: inline;
}

.ant-dropdown-button .ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}

@media (min-width: 0px) and (max-width: 1799px) {
  .company-list-btns {
    display: flex;
    flex-direction: row;
    min-width: 1200px;
    max-width: 1200px;
    margin: 20px auto;
    justify-content: flex-end;
  }
  .company-list-btns > button {
    background-color: #0b6277;
    color: #fff;
    outline: none;
    justify-content: flex-end;
    margin-left: 10px;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .company-list-btns {
    display: flex;
    flex-direction: row;
    min-width: 1600px;
    max-width: 1600px;
    margin: 20px auto;
    justify-content: flex-end;
  }
  .company-list-btns > button {
    background-color: #0b6277;
    color: #fff;
    outline: none;
    justify-items: flex-end;
    margin-left: 10px;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .company-list-condition {
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    flex-wrap: nowrap;
    border: 1px solid #c5dade;
    margin: 0 auto;
  }
  .company-list-condition .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .company-list-condition .search-th:nth-child(1) {
    border-left: none;
  }
  .company-list-condition .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .company-list-condition {
    max-width: 1600px;
    min-width: 1600px;
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    flex-wrap: nowrap;
    border: 1px solid #c5dade;
    margin: 0 auto;
  }
  .company-list-condition .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .company-list-condition .search-th:nth-child(1) {
    border-left: none;
  }
  .company-list-condition .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .company-list-result {
    margin: 30px auto;
    background-color: #fff;
    max-width: 1200px;
    min-width: 1200px;
    align-self: center;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .company-list-result {
    margin: 30px auto;
    background-color: #fff;
    max-width: 1600px;
    min-width: 1600px;
    align-self: center;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .user-list-btns {
    display: flex;
    flex-direction: row;
    min-width: 1200px;
    max-width: 1200px;
    margin: 20px auto;
    justify-content: flex-end;
  }
  .user-list-btns > button {
    background-color: #0b6277;
    color: #fff;
    outline: none;
    justify-content: flex-end;
    margin-left: 10px;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .user-list-btns {
    display: flex;
    flex-direction: row;
    min-width: 1600px;
    max-width: 1600px;
    margin: 20px auto;
    justify-content: flex-end;
  }
  .user-list-btns > button {
    background-color: #0b6277;
    color: #fff;
    outline: none;
    justify-items: flex-end;
    margin-left: 10px;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .user-list-condition {
    border: 1px solid #c5dade;
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
  }
  .user-list-condition .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border-bottom: 1px solid #c5dade;
  }
  .user-list-condition .first-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .user-list-condition .first-row .search-th:nth-child(1) {
    border-left: none;
  }
  .user-list-condition .first-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
  }
  .user-list-condition .second-row {
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
  .user-list-condition .second-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .user-list-condition .second-row .search-th:nth-child(1) {
    border-left: none;
  }
  .user-list-condition .second-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
    width: 180px;
  }
  .user-list-condition .second-row .empty {
    padding: 20px 0;
    text-align: center;
    border-left: 1px solid #c5dade;
    width: 788px;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .user-list-condition {
    border: 1px solid #c5dade;
    max-width: 1600px;
    min-width: 1600px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
  }
  .user-list-condition .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border-bottom: 1px solid #c5dade;
  }
  .user-list-condition .first-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .user-list-condition .first-row .search-th:nth-child(1) {
    border-left: none;
  }
  .user-list-condition .first-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
  }
  .user-list-condition .second-row {
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
  .user-list-condition .second-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .user-list-condition .second-row .search-th:nth-child(1) {
    border-left: none;
  }
  .user-list-condition .second-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
    width: 180px;
  }
  .user-list-condition .second-row .empty {
    padding: 20px 0;
    text-align: center;
    border-left: 1px solid #c5dade;
    width: 1059px;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .user-list-result {
    margin: 30px auto;
    background-color: #fff;
    max-width: 1200px;
    min-width: 1200px;
    align-self: center;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .user-list-result {
    margin: 30px auto;
    background-color: #fff;
    max-width: 1600px;
    min-width: 1600px;
    align-self: center;
  }
}
.table-guide {
  display: block;
  text-align: end;
  padding-right: 26px;
  font-size: 10px;
  font-style: italic;
  color: #8eb3ba;
}

.table {
  margin: 0 auto 10px auto;
}

.tbody {
  border: 1px solid #c5dade;
  margin: 5px 0 10px 0;
}

.tr {
  font-size: 11px;
}
.tr > .th {
  background-color: #f5f9fa;
  color: #8eb3ba;
  border-right: 1px solid #c5dade;
  padding: 15px 5px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #c5dade;
}
.tr > .th:last-of-type {
  border-left: 1px solid #c5dade;
  vertical-align: middle;
  border-bottom: 1px solid #c5dade;
}
.tr > .td {
  padding: 10px;
  border-bottom: 1px solid #ecf5f7;
}
.tr:last-of-type > .td {
  border-bottom: 1px solid #c5dade;
}

.table-btns {
  display: block;
  font-size: 11px;
}
.table-btns > .table-btn-reset {
  margin-left: 26px;
  margin-right: 358px;
}
.table-btns > .table-btn-save {
  text-align: end;
  margin-left: 10px;
}

.table-user {
  margin: 0 auto 10px auto;
  width: 92%;
}
.table-user > .tbody-user {
  border: 1px solid #c5dade;
  margin: 5px 0 10px 0;
}
.table-user > .tbody-user > .tr {
  font-size: 11px;
}
.table-user > .tbody-user > .tr > .th {
  background-color: #f5f9fa;
  color: #8eb3ba;
  border-right: 1px solid #c5dade;
  padding: 15px 5px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #c5dade;
  width: 15%;
  text-rendering: auto;
}
.table-user > .tbody-user > .tr > .th:last-of-type {
  border-left: 1px solid #c5dade;
  vertical-align: middle;
  border-bottom: 1px solid #c5dade;
}
.table-user > .tbody-user > .tr > .td {
  padding: 10px;
  border-bottom: 1px solid #ecf5f7;
  width: 35%;
}
.table-user > .tbody-user > .tr:last-of-type > .td {
  border-bottom: 1px solid #c5dade;
}

.table-guide-parent-company {
  display: block;
  text-align: end;
  padding-right: 26px;
  font-size: 10px;
  font-style: italic;
  color: #8eb3ba;
  margin-top: 20px;
}

.table-parent-company {
  margin: 0 auto 10px auto;
  width: 92%;
}
.table-parent-company > .tbody-parent-company {
  border: 1px solid #c5dade;
  margin: 5px 0 10px 0;
}
.table-parent-company > .tbody-parent-company > .tr {
  font-size: 11px;
}
.table-parent-company > .tbody-parent-company > .tr > .th {
  background-color: #f5f9fa;
  color: #8eb3ba;
  border-right: 1px solid #c5dade;
  padding: 15px 5px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #c5dade;
  width: 15%;
  text-rendering: auto;
}
.table-parent-company > .tbody-parent-company > .tr > .th:last-of-type {
  border-left: 1px solid #c5dade;
  vertical-align: middle;
  border-bottom: 1px solid #c5dade;
}
.table-parent-company > .tbody-parent-company > .tr > .td {
  padding: 10px;
  border-bottom: 1px solid #ecf5f7;
  width: 35%;
}
.table-parent-company > .tbody-parent-company > .tr:last-of-type > .td {
  border-bottom: 1px solid #c5dade;
}

.table-btns-parent-company {
  display: block;
  font-size: 11px;
  padding-left: 26px;
  margin-top: 35px;
  margin-bottom: 20px;
}
.table-btns-parent-company > .table-btn-save {
  margin-left: 10px;
}

.ant-select-selection-item {
  text-align: start;
}

.user-comp-search-btn {
  float: right;
  margin-bottom: 15px;
  margin-top: 0;
}

@media (min-width: 0px) and (max-width: 1799px) {
  .user-comp-search-condition {
    max-width: 812px;
    min-width: 812px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    flex-wrap: nowrap;
    border: 1px solid #c5dade;
    margin: 0 auto 15px auto;
  }
  .user-comp-search-condition .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 13%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .user-comp-search-condition .search-th:nth-child(1) {
    border-left: none;
  }
  .user-comp-search-condition .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
    width: 150px;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .user-comp-search-condition {
    max-width: 812px;
    min-width: 812px;
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    flex-wrap: nowrap;
    border: 1px solid #c5dade;
    margin: 0 auto 15px auto;
  }
  .user-comp-search-condition .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .user-comp-search-condition .search-th:nth-child(1) {
    border-left: none;
  }
  .user-comp-search-condition .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
    width: 150px;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .user-comp-search-result {
    margin: 0 auto 20px auto;
    height: 340px;
    max-width: 812px;
    min-width: 812px;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .user-comp-search-result {
    margin: 0 auto 20px auto;
    height: 340px;
    max-width: 812px;
    min-width: 812px;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .parent-company-list-btns {
    display: flex;
    flex-direction: row;
    min-width: 1200px;
    max-width: 1200px;
    margin: 20px auto;
    justify-content: flex-end;
  }
  .parent-company-list-btns > button {
    background-color: #0b6277;
    color: #fff;
    outline: none;
    justify-content: flex-end;
    margin-left: 10px;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .parent-company-list-btns {
    display: flex;
    flex-direction: row;
    min-width: 1600px;
    max-width: 1600px;
    margin: 20px auto;
    justify-content: flex-end;
  }
  .parent-company-list-btns > button {
    background-color: #0b6277;
    color: #fff;
    outline: none;
    justify-items: flex-end;
    margin-left: 10px;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .parent-company-list-condition {
    border: 1px solid #c5dade;
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
  }
  .parent-company-list-condition .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }
  .parent-company-list-condition .first-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .parent-company-list-condition .first-row .search-th:nth-child(1) {
    border-left: none;
  }
  .parent-company-list-condition .first-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .parent-company-list-condition {
    border: 1px solid #c5dade;
    max-width: 1600px;
    min-width: 1600px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
  }
  .parent-company-list-condition .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border-bottom: 1px solid #c5dade;
  }
  .parent-company-list-condition .first-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .parent-company-list-condition .first-row .search-th:nth-child(1) {
    border-left: none;
  }
  .parent-company-list-condition .first-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
  }
  .parent-company-list-condition .second-row {
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
  .parent-company-list-condition .second-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .parent-company-list-condition .second-row .search-th:nth-child(1) {
    border-left: none;
  }
  .parent-company-list-condition .second-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
    width: 180px;
  }
  .parent-company-list-condition .second-row .empty {
    padding: 20px 0;
    text-align: center;
    border-left: 1px solid #c5dade;
    width: 1059px;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .parent-company-list-result {
    margin: 30px auto;
    background-color: #fff;
    max-width: 1200px;
    min-width: 1200px;
    align-self: center;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .parent-company-list-result {
    margin: 30px auto;
    background-color: #fff;
    max-width: 1600px;
    min-width: 1600px;
    align-self: center;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .bl-list-btns {
    display: flex;
    flex-direction: row;
    min-width: 1200px;
    max-width: 1200px;
    margin: 20px auto;
    justify-content: flex-end;
  }
  .bl-list-btns > button {
    background-color: #0b6277;
    color: #fff;
    outline: none;
    justify-content: flex-end;
    margin-left: 10px;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .bl-list-btns {
    display: flex;
    flex-direction: row;
    min-width: 1600px;
    max-width: 1600px;
    margin: 20px auto;
    justify-content: flex-end;
  }
  .bl-list-btns > button {
    background-color: #0b6277;
    color: #fff;
    outline: none;
    justify-items: flex-end;
    margin-left: 10px;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .bl-list-condition {
    border: 1px solid #c5dade;
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
  }
  .bl-list-condition .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }
  .bl-list-condition .first-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .bl-list-condition .first-row .search-th:nth-child(1) {
    border-left: none;
  }
  .bl-list-condition .first-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .bl-list-condition {
    border: 1px solid #c5dade;
    max-width: 1600px;
    min-width: 1600px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
  }
  .bl-list-condition .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border-bottom: 1px solid #c5dade;
  }
  .bl-list-condition .first-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .bl-list-condition .first-row .search-th:nth-child(1) {
    border-left: none;
  }
  .bl-list-condition .first-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
  }
  .bl-list-condition .second-row {
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
  .bl-list-condition .second-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .bl-list-condition .second-row .search-th:nth-child(1) {
    border-left: none;
  }
  .bl-list-condition .second-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
    width: 180px;
  }
  .bl-list-condition .second-row .empty {
    padding: 20px 0;
    text-align: center;
    border-left: 1px solid #c5dade;
    width: 1059px;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .bl-list-result {
    margin: 30px auto;
    background-color: #fff;
    max-width: 1200px;
    min-width: 1200px;
    align-self: center;
    height: 700px;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .bl-list-result {
    margin: 30px auto;
    background-color: #fff;
    max-width: 1600px;
    min-width: 1600px;
    align-self: center;
    height: 700px;
  }
}
#bl-upload-wrap {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

#bl-upload-container {
  width: 90vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
#bl-upload-container #bl-upload-upsert-grid-wrap {
  width: 35%;
  height: 98vh;
}
#bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-btns {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px 0;
  justify-content: flex-end;
}
#bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-btns > button {
  font-size: 16px;
  background-color: #0b6277;
  color: #fff;
  outline: none;
  justify-content: flex-end;
}
#bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition {
  width: 100%;
  height: 386px;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #c5dade;
}
#bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .first-row, #bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .second-row, #bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .third-row, #bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .fourth-row {
  width: 100%;
}
#bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .first-row .search-th, #bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .second-row .search-th, #bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .third-row .search-th, #bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .fourth-row .search-th {
  background: #f5f9fa;
  color: #8eb3ba;
  height: 55px;
  line-height: 55px;
  width: 25%;
  text-align: center;
  float: left;
  border-bottom: 1px solid #c5dade;
  border-right: 1px solid #c5dade;
}
#bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .first-row .search-td, #bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .second-row .search-td, #bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .third-row .search-td, #bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .fourth-row .search-td {
  width: 70%;
  height: 50px;
  background: none;
  text-align: center;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
#bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .third-row .search-td {
  margin-top: 6px;
}
#bl-upload-container #bl-upload-upsert-grid-wrap .bl-upload-upsert-condition .fourth-row .search-td {
  margin-top: 3px;
}
#bl-upload-container #left-side {
  width: 65%;
  height: 100vh;
  margin: 0 10px 0 0;
}
#bl-upload-container #left-side .zoom-btns {
  width: 250px;
  height: 50px;
  padding: 17px 0;
}
#bl-upload-container #left-side .zoom-btns > button {
  width: 30px;
  height: 30px;
  line-height: 25px;
  color: #72b6c6;
  outline: none;
  justify-content: flex-end;
  border: 1px solid #72b6c6;
  background-color: #fff;
  margin-right: 5px;
  cursor: pointer;
}
#bl-upload-container #left-side .zoom-btns > button:hover {
  color: #fff;
  background-color: #72b6c6;
}
#bl-upload-container #left-side .zoom-btns > button:nth-child(3),
#bl-upload-container #left-side .zoom-btns > button:nth-child(4) {
  width: 60px;
}
#bl-upload-container #left-side .main-section {
  width: 100%;
  height: 92%;
  text-align: center;
  background-color: #fff;
  margin-top: 5px;
}
#bl-upload-container #left-side .import-area canvas,
#bl-upload-container #left-side .import-area img {
  width: 100%;
  vertical-align: top;
}
#bl-upload-container #sub-section {
  width: 100%;
  height: calc(100vh - 42%);
}
#bl-upload-container #sub-section .crop-btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 18px;
}
#bl-upload-container #sub-section .crop-btns > button {
  border: 1px solid #e0e0e0;
  color: #0b6277;
  background-color: #fff;
  padding: 0 auto;
  width: 190px;
  height: 40px;
  border-radius: 12px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  padding-top: 8px;
  margin-left: 5px;
}
#bl-upload-container #sub-section .crop-btns > button:hover {
  border: 1px solid #0b6277;
  color: #fff;
  background-color: #0b6277;
}
#bl-upload-container #sub-section .sub-section-cards {
  clear: both;
  overflow-y: auto;
  height: calc(100vh - 69%);
  padding-bottom: 15px;
}
#bl-upload-container #sub-section .sub-section-cards .card-item {
  border: 1px solid #e0e0e0;
  background-color: #fff;
  margin-bottom: 8px;
  padding: 13px;
}
#bl-upload-container #sub-section .sub-section-cards .invalid {
  border: 1px solid red;
}

dl {
  display: flex;
}
dl dt {
  width: 230px;
  font-size: 14px;
  margin-top: 7px;
}
dl dd {
  margin: 0 2.5px;
}
dl dd button {
  width: 90px;
}
dl dd button span {
  font-size: 12px;
}

@media all and (max-width: 1920px) {
  #bl-upload-container #sub-section {
    height: calc(100vh - 500px);
  }
  #bl-upload-container #sub-section .sub-section-cards {
    height: calc(100vh - 500px);
  }
}
.drag-uploader-tempo {
  width: 850px;
  height: 1200px;
}

.pdf-viewer {
  height: 100vh;
  max-width: 885px;
}
.pdf-viewer > div *::-moz-selection {
  background: rgba(255, 0, 0, 0.9);
  color: #000;
}
.pdf-viewer > div *::selection {
  background: rgba(255, 0, 0, 0.9);
  color: #000;
}
.pdf-viewer > .ng2-pdf-viewer-container {
  min-width: 590px;
  max-width: 100%;
}

@media (min-width: 0px) and (max-width: 1799px) {
  .bl-reg-btns {
    display: flex;
    flex-direction: row;
    min-width: 1200px;
    max-width: 1200px;
    margin: 20px auto;
    justify-content: flex-end;
  }
  .bl-reg-btns > button {
    background-color: #0b6277;
    color: #fff;
    outline: none;
    justify-content: flex-end;
    margin-left: 10px;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .bl-reg-btns {
    display: flex;
    flex-direction: row;
    min-width: 1600px;
    max-width: 1600px;
    margin: 20px auto;
    justify-content: flex-end;
  }
  .bl-reg-btns > button {
    background-color: #0b6277;
    color: #fff;
    outline: none;
    justify-items: flex-end;
    margin-left: 10px;
  }
}
@media (min-width: 0px) and (max-width: 1799px) {
  .bl-reg-condition {
    border: 1px solid #c5dade;
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
  }
  .bl-reg-condition .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }
  .bl-reg-condition .first-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .bl-reg-condition .first-row .search-th:nth-child(1) {
    border-left: none;
  }
  .bl-reg-condition .first-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
  }
}
@media (min-width: 1800px) and (max-width: 8000px) {
  .bl-reg-condition {
    border: 1px solid #c5dade;
    max-width: 1600px;
    min-width: 1600px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
  }
  .bl-reg-condition .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border-bottom: 1px solid #c5dade;
  }
  .bl-reg-condition .first-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .bl-reg-condition .first-row .search-th:nth-child(1) {
    border-left: none;
  }
  .bl-reg-condition .first-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
  }
  .bl-reg-condition .second-row {
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
  .bl-reg-condition .second-row .search-th {
    background: #f5f9fa;
    color: #8eb3ba;
    padding: 20px 0;
    width: 15%;
    text-align: center;
    border-right: 1px solid #c5dade;
    border-left: 1px solid #c5dade;
  }
  .bl-reg-condition .second-row .search-th:nth-child(1) {
    border-left: none;
  }
  .bl-reg-condition .second-row .search-td {
    background: #fff;
    text-align: center;
    margin: 0 auto;
    width: 180px;
  }
  .bl-reg-condition .second-row .empty {
    padding: 20px 0;
    text-align: center;
    border-left: 1px solid #c5dade;
    width: 1059px;
  }
}
.ant-radio-checked .ant-radio-inner {
  border-color: #0b6277;
}

.ant-radio-inner::after {
  border: 1px solid #0b6277;
  background-color: #0b6277;
}

.test-label {
  color: #0b6277;
}

.ant-modal-confirm .ant-modal-body {
  padding: 65px 32px 24px;
  height: 200px;
}

.ant-modal-body {
  width: 100%;
  height: 700px;
  padding: 25px;
  background: #fff;
}

.bl-reg-update {
  width: 100%;
  height: 555px;
  overflow-y: auto;
  overflow-x: hidden;
}

.bl-reg-desc {
  border: 1px solid #c5dade;
  margin-bottom: 20px;
}

.bl-reg-title {
  height: 50px;
  line-height: 50px;
  font-weight: 700;
  color: #0b6277;
  padding-left: 25px;
  background: #f5f9fa;
}

.bl-reg-useYn {
  color: #8eb3ba;
  padding: 20px;
}

.bl-reg-useYn > span {
  font-weight: 700;
}

.bl-reg-select {
  width: 100%;
  padding: 0 0 20px 20px;
}

.bl-reg-cInfo {
  display: flex;
  flex-direction: row;
  padding: 2px 0px;
}
.bl-reg-cInfo div {
  width: 250px;
  margin-left: 10px;
}
.bl-reg-cInfo span {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  line-height: 2.66em;
}
.bl-reg-cInfo:last-child {
  padding-bottom: 15px;
}

.bl-reg-cInfo > span {
  font-weight: 700;
  color: #0b6277;
}

.bl-reg-cInfo:last-child > span {
  width: 90px;
}

.bl-reg-update-btns {
  position: relative;
  left: 50%;
  width: 200px;
  margin-top: 40px;
  margin-left: 170px;
}

.bl-reg-btn-cancel {
  margin-right: 10px;
}

@media all and (max-width: 744px) {
  .bl-reg-cInfo {
    flex-direction: column;
  }

  .bl-reg-update-btns {
    left: 0;
    margin-left: 0;
  }
}