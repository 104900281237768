// btns

@include smDesktop {
  .company-list-btns {
    @include flex-row;
    min-width: 1200px;
    max-width: 1200px;
    margin: 20px auto;
    justify-content: flex-end;
    > button {
      background-color: #0b6277;
      color: #fff;
      outline: none;
      justify-content: flex-end;
      margin-left: 10px;
    }
  }
}

@include lgDesktop {
  .company-list-btns {
    @include flex-row;
    min-width: 1600px;
    max-width: 1600px;
    margin: 20px auto;
    justify-content: flex-end;
    > button {
      background-color: #0b6277;
      color: #fff;
      outline: none;
      justify-items: flex-end;
      margin-left: 10px;
    }
  }
}

// condition

@include smDesktop {
  .company-list-condition {
    
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 20px;
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    flex-wrap: nowrap;
    border: 1px solid #c5dade;
    margin: 0 auto;
    .search-th {
      background: #f5f9fa;
      color: #8eb3ba;
      padding: 20px 0;
      width: 15%;
      text-align: center;
      border-right: 1px solid #c5dade;
      border-left: 1px solid #c5dade;
      &:nth-child(1) {
        border-left: none;
      }
    }
    .search-td {
      background: #fff;
      text-align: center;
      margin: 0 auto;
    }
  }
}

@include lgDesktop {
  .company-list-condition {
    max-width: 1600px;
    min-width: 1600px;
    margin: 0 20px;
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    flex-wrap: nowrap;
    border: 1px solid #c5dade;
    margin: 0 auto;
    .search-th {
      background: #f5f9fa;
      color: #8eb3ba;
      padding: 20px 0;
      width: 15%;
      text-align: center;
      border-right: 1px solid #c5dade;
      border-left: 1px solid #c5dade;
      &:nth-child(1) {
        border-left: none;
      }
    }
    .search-td {
      background: #fff;
      text-align: center;
      margin: 0 auto;
    }
  }
}

// result

@include smDesktop {
  .company-list-result {
    margin: 30px auto;
    background-color: #fff;
    max-width: 1200px;
    min-width: 1200px;
    align-self: center;
  }
}

@include lgDesktop {
  .company-list-result {
    margin: 30px auto;
    background-color: #fff;
    max-width: 1600px;
    min-width: 1600px;
    align-self: center;
  }
}